export const bookQtys = [
    {
        id: 0,
        value: 0,
        input: '▾ 選択してください'
    },
    {
        id: 1,
        value: 1,
        input: '1 冊'
    },
    {
        id: 2,
        value: 2,
        input: '2 冊'
    },
    {
        id: 3,
        value: 3,
        input: '3 冊'
    },
    {
        id: 4,
        value: 4,
        input: '4 冊'
    },
    {
        id: 5,
        value: 5,
        input: '5 冊'
    },
    {
        id: 6,
        value: 6,
        input: '6 冊'
    },
    {
        id: 7,
        value: 7,
        input: '7 冊'
    },
    {
        id: 8,
        value: 8,
        input: '8 冊'
    },
    {
        id: 9,
        value: 9,
        input: '9 冊'
    },
    {
        id: 10,
        value: 10,
        input: '10 冊'
    },
    {
        id: 11,
        value: 11,
        input: '11 冊'
    },
    {
        id: 12,
        value: 12,
        input: '12 冊'
    },
    {
        id: 13,
        value: 13,
        input: '13 冊'
    },
    {
        id: 14,
        value: 14,
        input: '14 冊'
    },
    {
        id: 15,
        value: 15,
        input: '15 冊'
    },
    {
        id: 16,
        value: 16,
        input: '16 冊'
    },
    {
        id: 17,
        value: 17,
        input: '17 冊'
    },
    {
        id: 18,
        value: 18,
        input: '18 冊'
    },
    {
        id: 19,
        value: 19,
        input: '19 冊'
    },
    {
        id: 20,
        value: 20,
        input: '20 冊'
    }
]
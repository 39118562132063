import React, { useContext } from 'react';
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"
import { motion } from "framer-motion"
// Context
import ArchiveContext from '../../context/archiveContext'

const Card = ({ items }) => {
    const intl = useIntl()
    const language = intl.locale

    // Context
    const currentArchiveState = useContext(ArchiveContext)
    const { isArchiveView, archiveNumber } = currentArchiveState

    return (
        items.map(item => (
            <motion.li
                className="card"
                key={item._id}
                // whileTap={{ scale: 0.9 }}
                onClick={() => {
                    archiveNumber[1](item._id)
                    isArchiveView[1](true)
                }}>
                <Img fluid={item.childImageSharp.fluid} />
                <figcaption>
                    <p className="title">{item.title[language]}</p>
                    <p className="place">{item.place[language]}</p>
                </figcaption>
            </motion.li>
        ))
    )
}

export default Card;
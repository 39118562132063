import React from 'react';
import { useIntl } from 'gatsby-plugin-intl'
import './css/bookOrderResult.styl'
import NextBackBtn from './nextBackBtn';
import Loading from '../loading';
import { motion } from "framer-motion"
import { checkoutModalVariants } from '../../asset/variants-framer-motion/variants'

const OrderResult = ({
    processNum,
    setProcessNum,
    setIsBookOrdering,
    setIsArchiveOrdering,
    bookOrderState,
    orderID,
    isLoading,
    productType,
    setProductType }) => {

    const intl = useIntl()
    const language = intl.locale

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && (productType === 1 || productType === 2) &&
                <motion.div
                    className="bookOrderResult"
                    variants={checkoutModalVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit">
                    {bookOrderState === 'success' && <div className="success">
                        本が発注されました。下記の注文IDをお控えください。<br />
                        また、本が発送されましたら、メールでお知らせいたします。しばらくお待ち下さい。<br className="pc" />
                        これより、アーカイブサイトがご覧になれます。<br /><br />

                        注文ID ： <span className="id">{orderID}</span><br />
                        <small>
                            ※万が一、10日ほど経過しても発送のお知らせが届かない場合は、<br className="pc" />
                            コンタクトページから上記注文ID、お名前、電話番号を記載の上、ご一報ください。
                        </small>
                    </div>}
                    {bookOrderState === 'error' && <div className="error">
                        申し訳ありません。製本が発注されませんでした。<br />
                        コンタクトページより、「製本発注エラー」の件名でご一報ください。迅速に対応いたします。
                    </div>}
                    <NextBackBtn
                        processNum={processNum}
                        setProcessNum={setProcessNum}
                        setIsBookOrdering={setIsBookOrdering}
                        setIsArchiveOrdering={setIsArchiveOrdering}
                        backText={language === 'ja' ? 'アーカイブページへ' : 'Move to archive page.'}
                        nextRequired={false}
                        backRequired={true}
                        productType={productType}
                        setProductType={setProductType} />
                </motion.div>}

            {!isLoading && productType === 3 &&
                <motion.div
                    className="bookOrderResult"
                    variants={checkoutModalVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit">
                    {bookOrderState === 'success' &&
                        <div className="success">
                            {language === 'ja' ?
                                'アーカイブサイトのご購入が完了しました。\nこれより、アーカイブサイトがご覧になれます。' :
                                'Thank you! Purchasing process was completed.\nYou can see all archives from now.'}
                        </div>
                    }
                    {bookOrderState === 'error' &&
                        <div className="error">
                            {language === 'ja' ?
                                '決算が完了しませんでした。' :
                                'Sorry...\nPurchasing process was not completed.'}
                        </div>
                    }
                    <NextBackBtn
                        processNum={processNum}
                        setProcessNum={setProcessNum}
                        setIsBookOrdering={setIsBookOrdering}
                        setIsArchiveOrdering={setIsArchiveOrdering}
                        backText={language === 'ja' ? 'アーカイブページへ' : 'Move to archive page.'}
                        nextRequired={false}
                        backRequired={true}
                        productType={productType}
                        setProductType={setProductType} />
                </motion.div>
            }
        </>
    );
};

export default OrderResult;
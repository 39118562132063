import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import _ from 'lodash'
import './css/index.styl'
import Payment from './payment';
import OrderResult from './orderResult';

const CheckoutArchiveModal = ({
    productType,
    setProductType,
    setIsBookOrdering,
    setIsArchiveOrdering,
    setArchivePaid,
    setPaidProductType,
    userEmail }) => {

    // Hook
    const [processNum, setProcessNum] = useState(0)
    const NotVerticalCenterProcess = [0]
    const [bookOrderState, setBookOrderState] = useState()
    const [orderID, setOrderID] = useState()
    const [isLoading, setLoading] = useState(true)

    return (
        <div className="checkoutModal">
            <div className={"modalContentArea" + (NotVerticalCenterProcess.includes(processNum) ? ' not_V_Center' : '')}>
                {processNum === 0 &&
                    <Payment
                        processNum={processNum}
                        setProcessNum={setProcessNum}
                        setProductType={setProductType}
                        productType={productType}
                        setBookOrderState={setBookOrderState}
                        setIsBookOrdering={setIsBookOrdering}
                        setIsArchiveOrdering={setIsArchiveOrdering}
                        setLoading={setLoading}
                        setArchivePaid={setArchivePaid}
                        setPaidProductType={setPaidProductType}
                        userEmail={userEmail} />}

                {processNum === 1 &&
                    <OrderResult
                        processNum={processNum}
                        bookOrderState={bookOrderState}
                        orderID={orderID}
                        setProcessNum={setProcessNum}
                        productType={productType}
                        setProductType={setProductType}
                        setIsBookOrdering={setIsBookOrdering}
                        setIsArchiveOrdering={setIsArchiveOrdering}
                        isLoading={isLoading} />
                }
            </div>
        </div>
    )
}

export default CheckoutArchiveModal
import React, { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import _ from 'lodash'
import ShippingForm from './shippingForm';
import './css/index.styl'
import Explanation from './explanation';
import Payment from './payment';
import OrderResult from './orderResult';

const CheckoutBookModal = ({
    productType,
    setProductType,
    setIsBookOrdering,
    setIsArchiveOrdering,
    setBookPaid,
    setArchivePaid,
    setPaidProductType,
    userEmail }) => {

    // Hook
    const [processNum, setProcessNum] = useState(0)
    const NotVerticalCenterProcess = [1, 2, 3]
    const [bookOrderState, setBookOrderState] = useState()
    const [orderID, setOrderID] = useState()
    const [isLoading, setLoading] = useState(true)
    const [bookQuantity, setBookQuantity] = useState(0)
    const [receiptEmail, setReceiptEmail] = useState({ email: '' })

    // Effect
    useEffect(() => {
        if (!!userEmail) setReceiptEmail(userEmail)
        if (productType === 1) setBookQuantity(1)
    }, [])


    return (
        <div className="checkoutModal">
            <div className={"modalContentArea" + (NotVerticalCenterProcess.includes(processNum) ? ' not_V_Center' : '')}>
                {processNum === 0 &&
                    <Explanation
                        processNum={processNum}
                        setProcessNum={setProcessNum}
                        productType={productType}
                        setProductType={setProductType}
                        setIsBookOrdering={setIsBookOrdering}
                        setIsArchiveOrdering={setIsArchiveOrdering}
                        bookQuantity={bookQuantity}
                        setBookQuantity={setBookQuantity}
                        userEmail={userEmail}
                        receiptEmail={receiptEmail}
                        setReceiptEmail={setReceiptEmail} />}
                {processNum === 1 &&
                    <Payment
                        processNum={processNum}
                        setProcessNum={setProcessNum}
                        productType={productType}
                        bookQuantity={bookQuantity}
                        userEmail={userEmail || receiptEmail.email} />}

                {(processNum === 2 || processNum === 3) &&
                    <ShippingForm
                        processNum={processNum}
                        setProcessNum={setProcessNum}
                        setBookOrderState={setBookOrderState}
                        setOrderID={setOrderID}
                        setLoading={setLoading}
                        bookQuantity={bookQuantity}
                        productType={productType}
                        setBookPaid={setBookPaid}
                        setArchivePaid={setArchivePaid}
                        setPaidProductType={setPaidProductType}
                        userEmail={userEmail || receiptEmail.email} />
                }
                {processNum === 4 &&
                    <OrderResult
                        processNum={processNum}
                        setProcessNum={setProcessNum}
                        setIsBookOrdering={setIsBookOrdering}
                        bookOrderState={bookOrderState}
                        orderID={orderID}
                        isLoading={isLoading}
                        productType={productType}
                        setProductType={setProductType} />
                }
            </div>
        </div>
    )
}

export default CheckoutBookModal
import React, { useState } from 'react';
import { useIntl } from "gatsby-plugin-intl"
import { getEpilogues } from '../../data/epilogue';
import './css/epilogue.styl'
import { motion } from 'framer-motion';
// Framer motion Variants
import { fadeInVariants } from '../../asset/variants-framer-motion/variants';
import BackArrow from './backArrow';

const Epilogue = () => {
    const intl = useIntl()
    const language = intl.locale
    const epilogues = getEpilogues()

    // Hook
    const [showNum, setShowNum] = useState(1)
    const [activeCard, setActiveCard] = useState(false)

    // Fileter selected epilogue
    const selectedEpologue = epilogues.filter(epi => epi._id === showNum)[0]

    return (
        <>
            {!activeCard && <div className="epilogue">
                {epilogues.map(epilogue => (
                    <motion.div
                        variants={fadeInVariants}
                        initial="hidden"
                        animate="visible"
                        transition="transition"
                        key={epilogue._id}
                        className="cardWrap">
                        <div className="card"
                            onClick={() => {
                                setShowNum(epilogue._id)
                                setActiveCard(!activeCard)
                                if (typeof window !== "undefined") window.scrollTo(0, 0)
                            }}>
                            <h2>{epilogue.title[language]}</h2>
                            <div className="comment">{epilogue[language]}</div>
                            <figure
                                className={"avatar " + epilogue.avatarName}

                            ></figure>
                            <div className="nameWrap ">
                                <p className="name">{epilogue.name[language]}</p>
                                <p className="jobTitle">{epilogue.jobTitle[language]}</p>
                            </div>
                        </div>
                    </motion.div>
                ))
                }

            </div >}
            {activeCard && <div className="epilogue active">
                <div
                    key={selectedEpologue._id}
                    className="cardWrap">
                    <BackArrow setActiveCard={setActiveCard} position='top' />
                    <motion.div className="card active"
                        variants={fadeInVariants}
                        initial="hidden"
                        animate="visible"
                        transition={{ duration: .6, type: "tween" }}
                        onClick={() => {
                            setShowNum(selectedEpologue._id)
                            setActiveCard(!activeCard)
                        }}>
                        <h2>{selectedEpologue.title[language]}</h2>
                        <div className="nameWrap ">
                            <p className="name">{selectedEpologue.name[language]}{'　'}
                                <span className="jobTitle">{selectedEpologue.jobTitle[language]}</span>
                            </p>
                        </div>
                        <div className="comment">{selectedEpologue[language]}</div>
                        <figure
                            className={"avatar active " + selectedEpologue.avatarName}

                        ></figure>
                        <BackArrow setActiveCard={setActiveCard} position='bottom' />
                    </motion.div>
                </div>

            </div>}
        </>
    );
};

export default Epilogue;
import { useStaticQuery, graphql } from "gatsby"

const useLandingPageFeatures = () => {
  const { allLandingPageFeaturesJson } = useStaticQuery(
    graphql`
      query {
        allLandingPageFeaturesJson {
          nodes {
            bookAndArchive {
              en
              ja
            }
            book {
              en
              ja
            }
            archive {
              en
              ja
            }
            annotation {
              en
              ja
            }
          }
        }
      }
    `
  )

  return allLandingPageFeaturesJson.nodes
}

export default useLandingPageFeatures

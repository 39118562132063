import React, { useState, useEffect } from "react"
import { useIntl, Link, changeLocale } from "gatsby-plugin-intl"
import { Router } from "@reach/router"
// Component
import Layout from "../components/layout"
import SEO from "../components/seo"
import AllArchives from '../components/archive/all-archives'
import LandingPage from '../components/archive/landingPage'
import ArchiveView from '../components/archive/archive-view'
import Loading from '../components/loading'
import Notification from '../components/notification'
import CheckoutBookModal from '../components/checkoutModal/checkoutBookModal'
import CheckoutArchiveModal from '../components/checkoutModal/checkoutArchiveModal'
import AskBookPageSave from '../components/archive/askBookPageSave'
import AskDefaultLang from '../components/archive/askDefaultLang'

// Context
import ArchiveContext from '../context/archiveContext'
// Auth0
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"
// Stripe
import { loadStripe } from '@stripe/stripe-js'
// FsLightBox
import FsLightbox from 'fslightbox-react'
import useBookPagesImgs from '../hooks/use-all-book-pages';
import useBookEnPagesImgs from '../hooks/use-all-book-en-pages';

import pdf000 from '../images/book/000_Cover.jpg'
import pdf001 from '../images/book/001.jpg'
import pdf002 from '../images/book/002.jpg'
import pdf003 from '../images/book/003.jpg'
// Css
import "./css/archive.styl"
import { stringify } from "joi-browser"

const { GATSBY_FUNCTION_DOMAIN } = process.env
const { GATSBY_STRIPE_PUBLISHABLE_KEY } = process.env
const stripePromise = loadStripe(`${GATSBY_STRIPE_PUBLISHABLE_KEY}`);
// const Home = ({ user }) => {
//     return <p>Hi, {user.name ? user.name : "friend"}!</p>
// }

const Archive = ({ location }) => {
    const intl = useIntl()
    const language = intl.locale

    // User client side info
    const user = getProfile()
    const userEmail = user.email
    const isUserDataStored = !!Object.keys(user).length

    //Hook
    // const [fetchedUserStatus, setFetchedUserStatus] = useState(false)
    const [isArchivePaid, setArchivePaid] = useState()
    const [isBookPaid, setBookPaid] = useState()
    const [savedArchives, setSavedArchives] = useState([])
    const [isLogin, setIsLogin] = useState()
    const [isLoading, setLoading] = useState(true)
    // Archive View
    const [isArchiveView, setIsArchiveView] = useState(false)
    const [archiveNumber, setArchiveNumber] = useState([])
    // Error-Success Notification
    const [isNotification, setIsNotification] = useState()
    const [isSuccessNotification, setIsSuccessNotification] = useState()
    const [message, setMessage] = useState()
    // Book order process
    const [productType, setProductType] = useState(0)
    const [paidProductType, setPaidProductType] = useState(0)
    const [isBookOrdering, setIsBookOrdering] = useState(false)
    const [isArchiveOrdering, setIsArchiveOrdering] = useState(false)
    // FsLightBox
    const [lightBoxToggler, setLightBoxToggler] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [isAskingBookPageSave, setIsAskingBookPageSave] = useState(false)
    // Language Default setting
    const [isAskingDefaultLang, setIsAskingDefaultLang] = useState(false)

    const bookPagesImgsArray = useBookPagesImgs()
        .map(node => [node.childImageSharp.fluid.src])
        .reduce((a, b) => a.concat(b))

    const bookEnPagesImgsArray = useBookEnPagesImgs()
        .map(node => [node.childImageSharp.fluid.src])
        .reduce((a, b) => a.concat(b))

    useEffect(() => {
        setIsLogin(isAuthenticated())
    }, [])

    useEffect(() => {
        if (isLogin !== undefined && !isLogin) {
            setLoading(false)
            return
        }

        if (isLogin && !isUserDataStored) {
            logout()
            return
        }

        if (
            isLogin &&
            isUserDataStored &&
            isArchivePaid === undefined &&
            isBookPaid === undefined &&
            typeof window !== 'undefined' && window.location.search.indexOf('session_id') < 0
        ) {
            const fetchUserDetail = async (user) => {
                try {
                    // Check User purchase history
                    const userDetail =
                        await fetch(`${GATSBY_FUNCTION_DOMAIN}/fetch-paid-status?user_id=${user.sub}`)
                            .then(data => data.json())
                    const archiveStatus = !userDetail.app_metadata.isArchivePaid ? false : true
                    const bookStatus = !userDetail.app_metadata.isBookPaid ? false : true
                    const savedSettingLang = userDetail.user_metadata.savedDefaultLang
                    setArchivePaid(archiveStatus)
                    setBookPaid(bookStatus)
                    setSavedArchives(userDetail.user_metadata.savedArchives)
                    if (!!userDetail.user_metadata.savedBookPage) setInitialPage(userDetail.user_metadata.savedBookPage)
                    if (!savedSettingLang) setIsAskingDefaultLang(true)
                } catch (err) {
                    handleNotification('error',
                        language === 'en' ?
                            'Failed to check user status from server.' :
                            'ユーザーデータをサーバーから取得できませんでした。')
                }
                await setLoading(false)
            }
            fetchUserDetail(user)
        }
    }, [isLogin])

    useEffect(() => {
        if (isLogin && typeof window !== 'undefined') {
            const fetchUserSaveArchive = async (user) => {
                try {
                    const stringfiedArchives = JSON.stringify(savedArchives)
                    await fetch(`${GATSBY_FUNCTION_DOMAIN}/patch-user-save-archive?user_id=${user.sub}&savedArchives=${stringfiedArchives}`)
                        .then(data => data.json())
                } catch (err) {
                    handleNotification('error',
                        language === 'en' ?
                            'Failed to save your favorite archive to server.' :
                            'サーバーにアーカイブを保存できませんでした。')
                }
            }
            fetchUserSaveArchive(user)
        }
    }, [savedArchives])

    useEffect(() => {
        if (
            isLogin &&
            isUserDataStored &&
            !!paidProductType) {

            const currentPaidStatus = { isArchivePaid: isArchivePaid, isBookPaid: isBookPaid }
            const paidStatus = JSON.stringify(currentPaidStatus)

            const fetchAfterPurchase = async (user) => {
                setLoading(true)
                // After payment Patch user app_metadata to Auth0
                try {
                    await fetch(`${GATSBY_FUNCTION_DOMAIN}/patch-purchase-product?user_id=${user.sub}&paidStatus=${paidStatus}`)
                        .then(data => data.json())
                } catch (err) {
                    handleNotification('error',
                        language === 'en' ?
                            'Failed to update user purchase record to server' :
                            'サーバーへ購入履歴をアップデートすることができませんでした。')
                    throw new Error('ERROR : ', err)
                }
                await setLoading(false)
            }
            fetchAfterPurchase(user)
        }
    }, [paidProductType])


    // Functions
    const handlePurchaseArchive = async (event) => {
        try {
            const { id: sessionId } =
                await fetch(`${GATSBY_FUNCTION_DOMAIN}/get-checkout-session-id-archive-ja?user_id=${user.sub}`)
                    .then(data => data.json())

            const stripe = await stripePromise;
            const res = await stripe.redirectToCheckout({
                sessionId,
            });
        } catch (err) {
            handleNotification('error',
                language === 'en' ?
                    'Fail to move to checkout.' :
                    'チェックアウトへ移行できませんでした。')
        }
    }

    const handleBookPageSave = async (page) => {
        const fetchBookPagesSave = async (user) => {
            try {
                const savedBookPage = JSON.stringify(page)
                await fetch(`${GATSBY_FUNCTION_DOMAIN}/patch-book-pages-save?user_id=${user.sub}&savedBookPage=${savedBookPage}`)
                    .then(data => data.json())
            } catch (err) {
                handleNotification('error',
                    language === 'en' ?
                        'Failed to save the last page you read.' :
                        'サーバーに最後のページを記録できませんでした。')
            }
        }
        fetchBookPagesSave(user)
    }

    const handleDefaultLang = async (lang) => {
        const fetchDefaultLangSave = async (user) => {
            try {
                const savedDefaultLang = JSON.stringify(lang)
                await fetch(`${GATSBY_FUNCTION_DOMAIN}/patch-default-lang-save?user_id=${user.sub}&savedDefaultLang=${savedDefaultLang}`)
                    .then(data => data.json())
                if (lang !== language) changeLocale(lang)
            } catch (err) {
                handleNotification('error',
                    language === 'en' ?
                        'Failed to save default language.' :
                        'アカウントに言語設定ができませんでした。')
            }
        }
        fetchDefaultLangSave(user)
    }

    //// -- Notification
    const handleNotification = (type, message) => {
        setIsNotification(true)
        setMessage(message)
        if (type === 'success') {
            setIsSuccessNotification(true)
            return setTimeout(() => {
                setIsNotification(false)
                setMessage('')
            }, 200000)
        } else if (type === 'error') {
            return setIsSuccessNotification(false)
        }
    }



    return (
        <ArchiveContext.Provider value={
            {
                isArchiveView: [isArchiveView, setIsArchiveView],
                archiveNumber: [archiveNumber, setArchiveNumber]
            }
        }>
            <Layout location={location} isLogin={isLogin} noMargin={true} setIsAskingDefaultLang={setIsAskingDefaultLang}>
                <SEO
                    location={location}
                    lang={language}
                    title={language !== "ja" ? "Archive" : "アーカイブ"}
                    keywords={[`Archive`, `アーカイブ`, `放射線像`, `Autoradiograph`]}
                    description={language !== 'ja' ? `Autoradiograph's archive` : `放射線像プロジェクトのアーカイブ`}
                />
                {isNotification &&
                    <Notification
                        isSuccessNotification={isSuccessNotification}
                        setIsNotification={setIsNotification}
                        message={message}
                        setMessage={setMessage} />}
                {isLoading && <Loading />}
                {!isLoading && !isArchivePaid &&
                    <LandingPage
                        user={user}
                        isLogin={isLogin}
                        isArchivePaid={isArchivePaid}
                        isBookPaid={isBookPaid}
                        setIsLogin={setIsLogin}
                        handlePurchaseArchive={handlePurchaseArchive}
                        setProductType={setProductType}
                        setIsBookOrdering={setIsBookOrdering}
                        setIsArchiveOrdering={setIsArchiveOrdering}
                    />
                }
                {!isLoading && isLogin && isArchivePaid &&
                    <AllArchives
                        savedArchives={savedArchives}
                        setSavedArchives={setSavedArchives}
                        setIsBookOrdering={setIsBookOrdering}
                        setProductType={setProductType}
                        isBookPaid={isBookPaid}
                        setIsAskingBookPageSave={setIsAskingBookPageSave}
                    />
                }
            </Layout>
            {/* Start ArchiveView */}
            {isArchiveView &&
                <ArchiveView
                    savedArchives={savedArchives}
                    setSavedArchives={setSavedArchives}
                    isAuthenticated={isAuthenticated}
                />
            }
            {/* Start FsLightbox */}
            { isAskingBookPageSave &&
                <AskBookPageSave
                    initialPage={initialPage}
                    setInitialPage={setInitialPage}
                    toggler={lightBoxToggler}
                    setToggler={setLightBoxToggler}
                    setIsAskingBookPageSave={setIsAskingBookPageSave}
                    totalPageNumber={language === 'ja' ? bookPagesImgsArray.length - 1 : bookEnPagesImgsArray.length - 1}
                />
            }
            <FsLightbox
                toggler={lightBoxToggler}
                sourceIndex={initialPage}
                sources={language === 'ja' ? bookPagesImgsArray : bookEnPagesImgsArray}
                onSlideChange={(fsLightbox) => { setInitialPage(fsLightbox.stageIndexes.current) }}
                slide={initialPage}
                onClose={(fsLightbox) => handleBookPageSave(fsLightbox.stageIndexes.current)}
            />
            {/* Start asking Default Language */}
            {isAskingDefaultLang &&
                <AskDefaultLang
                    handleDefaultLang={handleDefaultLang}
                    setIsAskingDefaultLang={setIsAskingDefaultLang}
                />}
            {/* Start CheckoutModal */}
            {isBookOrdering &&
                <CheckoutBookModal
                    productType={productType}
                    setProductType={setProductType}
                    setIsBookOrdering={setIsBookOrdering}
                    setIsArchiveOrdering={setIsArchiveOrdering}
                    setBookPaid={setBookPaid}
                    setArchivePaid={setArchivePaid}
                    setPaidProductType={setPaidProductType}
                    userEmail={userEmail} />}
            {isArchiveOrdering &&
                <CheckoutArchiveModal
                    productType={productType}
                    setProductType={setProductType}
                    setIsBookOrdering={setIsBookOrdering}
                    setIsArchiveOrdering={setIsArchiveOrdering}
                    setArchivePaid={setArchivePaid}
                    setPaidProductType={setPaidProductType}
                    userEmail={userEmail} />}
        </ArchiveContext.Provider >
    )
}

export default Archive

import React from 'react';
import "./css/backArrow.styl"

const BackArrow = ({ setActiveCard, position }) => {

    return (
        <div className={"backArrow " + (!!position ? position : '')} onClick={() => { setActiveCard(false) }}>&larr;</div>
    )
}

export default BackArrow;
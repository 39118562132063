import { useStaticQuery, graphql } from "gatsby"

const ControllerText = () => {
  const { allArchiveViewControllerJson } = useStaticQuery(
    graphql`
      query {
        allArchiveViewControllerJson {
          nodes {
            description {
              en
              ja
            }
            change {
              en
              ja
            }
            comparison {
              en
              ja
            }
            location {
              en
              ja
            }
            help {
              en
              ja
            }
          }
        }
      }
    `
  )

  return allArchiveViewControllerJson.nodes
}

export default ControllerText

import { useStaticQuery, graphql } from "gatsby"

const HelpText = () => {
  const { allArchiveViewHelpJson } = useStaticQuery(
    graphql`
    query {
      allArchiveViewHelpJson {
        nodes {
          zoom {
            title {
              en
              ja
            }
            content {
              en
              ja
            }
          }
          threeD {
            title {
              en
              ja
            }
            content {
              en
              ja
            }
          }
          location {
            title {
              en
              ja
            }
            content {
              en
              ja
            }
          }
          color {
            title {
              en
              ja
            }
            content {
              en
              ja
            }
          }
          copyright {
            title {
              en
              ja
            }
            content {
              en
              ja
            }
          }
        }
      }
    }        
    `
  )

  return allArchiveViewHelpJson.nodes
}

export default HelpText

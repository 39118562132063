import React from 'react';
import { handleChange } from '../utils/form-functions';

const Input = ({ name, type = 'text', label, shippingDetail, errors, schema, setShippingDetail, setErrors, placeholder, errorMessage }) => {
    return (
        <>
            <label htmlFor="">{label}</label>
            <input type={type} name={name}
                onChange={e => handleChange(e, shippingDetail, errors, schema, setShippingDetail, setErrors)}
                placeholder={placeholder} value={shippingDetail[name] || ""} />
            {errors[name] && (<div className="error">{errorMessage}</div>)}
        </>
    )
};

export default Input;
import { useStaticQuery, graphql } from "gatsby"

const useArchiveLandingPageImage = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativeDirectory: { eq: "archive" }
          name: {
            in: ["050", "058", "067", "009", "147", "175"]
          }
        }
        sort: { fields: [relativePath], order: DESC }
      ) {
        nodes {
          childImageSharp {
            id
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return allFile.nodes
}

export default useArchiveLandingPageImage

import React from 'react';
import { login, logout, isAuthenticated, getProfile } from "../../utils/auth"
import Img from "gatsby-image"
import { useIntl } from 'gatsby-plugin-intl'
// Components
import CvSection from './cv-section';
import { cvText, cvSingleButtonText } from '../../data/landing-page';
import Card from './card';
// Hooks
import useArchiveLandingPageImage from "../../hooks/use-archive-landing-page-image"
import useArchiveLandingPageImageList from "../../hooks/use-archive-landing-page-image-list"
import getArrayObjConcat from '../../utils/get-arrayObject-concat';
import useAllArchivesList from '../../hooks/use-all-archive-list';
// CSS
import './css/landingPage.styl';
import './css/card.styl';
import dummyImg from '../../images/archive/asset/dummy.png';

const LandingPage = ({
    user,
    isLogin,
    setIsLogin,
    isArchivePaid,
    isBookPaid,
    handlePurchaseArchive,
    productType,
    setProductType,
    isBookOrdering,
    setIsBookOrdering,
    isArchiveOrdering,
    setIsArchiveOrdering }) => {

    const intl = useIntl()
    const language = intl.locale

    const list = useArchiveLandingPageImageList()
    const images = useArchiveLandingPageImage()
    const items = getArrayObjConcat(
        images,
        list
    )
    const allArchiveList = useAllArchivesList()

    const handleLogin = () => {
        login()
        return <p>Redirecting to login...</p>
    }

    return (
        <section className="archive component">
            <h1 className="archive_title">Archive</h1>
            <CvSection items={cvText}
                isArchivePaid={isArchivePaid}
                isBookPaid={isBookPaid}
                setProductType={setProductType}
                setIsBookOrdering={setIsBookOrdering}
                setIsArchiveOrdering={setIsArchiveOrdering} />
            <section className="landing_content">
                <h3>{language === 'en' ? 'Sample archives' : 'アーカイブ見本'}</h3>
                <ul className="archiveWrap">
                    <Card items={items} />
                    <li className="dummy"><img src={dummyImg} alt="dummy" /></li>
                    <li className="dummy"><img src={dummyImg} alt="dummy" /></li>
                    <li className="dummy"><img src={dummyImg} alt="dummy" /></li>
                </ul>
                {/* {!isLogin &&
                    <button className="cv_bottom" onClick={handleLogin}>{cvSingleButtonText.loginAndArchive[language]}</button>}
                    {isLogin && !isArchivePaid &&
                    <button className="cv_bottom archive" onClick={handlePurchaseArchive}>{cvSingleButtonText.button[language]}</button>} */}
            </section>
            <section className="landing_list">
                <h3>{language === 'en' ? 'Archive list' : 'アーカイブリスト'}</h3>
                {/* <p>{language === 'en' ?
                    'This Archive app includes the following autoradiographs' :
                    'このアーカイブサイトは、次の放射線像を収録しています。'}</p> */}
                <ul>
                    {allArchiveList.map(list => (
                        <li key={list._id}>
                            <div>{list.title[language]}</div>
                            <div className="placeYear">
                                <span className="place">{list.place[language] && list.place[language]}</span>
                                {!!list.place[language] ? `／${list.year}` : list.year}
                            </div>
                        </li>
                    ))}
                </ul>
            </section>
            {language === 'ja' && <section className="privacy_law">
                <ul>
                    <li><a href="../privacy" target="_blank">プライバシーポリシー</a></li>
                    <li><a href="../law" target="_blank">特定商取引法に基づく表記</a></li>
                </ul>
            </section>}
        </section>
    )
}

export default LandingPage;
import React, { useState, useEffect } from "react"
import './css/explanation.styl'
import NextBackBtn from './nextBackBtn';
import OrderStep from './orderStep';
// Framer motion and Variants
import { motion } from "framer-motion"
import { checkoutModalVariants } from '../../asset/variants-framer-motion/variants'
import { bookQtys } from '../../data/book_quantity';
import Joi from "joi-browser"
import { handleChange } from '../../utils/form-functions';
import Language from "../language";

const Explanation = ({
    processNum,
    setProcessNum,
    productType,
    setProductType,
    setIsBookOrdering,
    setIsArchiveOrdering,
    bookQuantity,
    setBookQuantity,
    userEmail,
    receiptEmail,
    setReceiptEmail }) => {

    // Hook
    const [errors, setErrors] = useState({})
    const [isReceiptRequired, setIsReceiptRequired] = useState(false)

    // useEffect
    useEffect(() => {
        setReceiptEmail('')
    }, [])

    //// Joi Schema ////
    const schema = {
        email: Joi.string().email()
            .trim()
            .required()
            .label(" メールアドレス ")
    }

    // Function
    const selectBookQuantity = ({ currentTarget: input }) => {
        const num = Number(input.value)
        setBookQuantity(num)
    }

    const handleRadioButton = (input) => setIsReceiptRequired(input)


    const handleButtonDisabled = () => {
        if (productType !== 2) return false
        else if (bookQuantity === 0) return true
        else if (!isReceiptRequired) return false
        else if (!!receiptEmail.email && !errors.email) return false
        else return true
    }

    return (
        <motion.div
            className="explanationWrap"
            variants={checkoutModalVariants}
            initial="hidden"
            animate="visible"
            exit="exit">
            <p className="sentence">
                {productType === 2 && '1. '}次のステップを経て、製本が発注されます。<br />途中、ブラウザの「戻る」ボタンを押したり、ページの離脱をされないようご注意ください。
            </p>
            <OrderStep processNum={processNum} />
            {productType === 2 &&
                <div className="book_quantity">
                    <label htmlFor="">2. 本の数量をお選びください。</label>
                    <select type="text" name="quantity"
                        onChange={e => { selectBookQuantity(e) }}
                        value={bookQuantity}>
                        {bookQtys.map(qty => (
                            <option key={qty.id} value={qty.value}>
                                {qty.input}
                            </option>))}
                    </select>
                </div>
            }
            {productType === 2 && !userEmail &&
                <div className="receipt">
                    <label htmlFor="">3. 領収書は必要ですか？</label>
                    <span className="radioArea">
                        <input type="radio" name="isReceiptRequired" onChange={() => handleRadioButton(true)} />
                        <label>はい</label>
                        <input type="radio" name="isReceiptRequired" onChange={() => {
                            handleRadioButton(false)
                            setReceiptEmail('')
                        }} defaultChecked />
                        <label>いいえ</label>
                    </span>

                    {isReceiptRequired &&
                        <motion.div
                            className="receipt_email"
                            variants={checkoutModalVariants}
                        >
                            <label htmlFor="">送付先のメールアドレスを入力してください。</label>
                            <input type='text' name='email'
                                onChange={e => handleChange(e, receiptEmail, errors, schema, setReceiptEmail, setErrors)}
                                placeholder='name@exmaple.com' value={receiptEmail.email || ""} />
                            {errors.email && (<div className="error">※メールアドレスを入力してください。</div>)}
                        </motion.div>
                    }
                </div>
            }
            <NextBackBtn
                processNum={processNum}
                setProcessNum={setProcessNum}
                setProductType={setProductType}
                setIsBookOrdering={setIsBookOrdering}
                setIsArchiveOrdering={setIsArchiveOrdering}
                nextText={'OK'}
                backText={'戻る'}
                nextRequired={true}
                backRequired={true}
                disabled={handleButtonDisabled()} />
            <div className="caution">
                受注生産のため商品に欠陥がある場合を除き、返品には応じかねます。<br className="cautionPC" />また商品の発送先は、<strong><u>日本国内のみ</u></strong>に対応しております。<br className="cautionPC" />
                    予めご了解いただきまして、発注をお願いいたします。
                    {productType === 1 &&
                    <><br /><br />
                    アーカイブサイトは、以下のブラウザ（最新版）にて動作確認をしております。<br className="cautionPC" />
                    IEでは動作しませんので、予めご了解ください。<br />
                    Windows - Google Chrome / FireFox、Mac - Google Chrome / FireFox / Safari<br />
                    iOS - Google Chrome / Brave、Android - Google Chrome</>
                }
            </div>

        </motion.div>
    )
}

export default Explanation;
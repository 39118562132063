export const prefs = [
    {
        id: 0,
        value: 0,
        text: '▾ 選択してください。'
    },
    {
        id: 1,
        value: 1,
        text: '北海道'
    },
    {
        id: 2,
        value: 2,
        text: '青森県'
    },
    {
        id: 3,
        value: 3,
        text: '岩手県'
    },
    {
        id: 4,
        value: 4,
        text: '宮城県'
    },
    {
        id: 5,
        value: 5,
        text: '秋田県'
    },
    {
        id: 6,
        value: 6,
        text: '山形県'
    },
    {
        id: 7,
        value: 7,
        text: '福島県'
    },
    {
        id: 8,
        value: 8,
        text: '茨城県'
    },
    {
        id: 9,
        value: 9,
        text: '栃木県'
    },
    {
        id: 10,
        value: 10,
        text: '群馬県'
    },
    {
        id: 11,
        value: 11,
        text: '埼玉県'
    },
    {
        id: 12,
        value: 12,
        text: '千葉県'
    },
    {
        id: 13,
        value: 13,
        text: '東京都'
    },
    {
        id: 14,
        value: 14,
        text: '神奈川県'
    },
    {
        id: 15,
        value: 15,
        text: '新潟県'
    },
    {
        id: 16,
        value: 16,
        text: '富山県'
    },
    {
        id: 17,
        value: 17,
        text: '石川県'
    },
    {
        id: 18,
        value: 18,
        text: '福井県'
    },
    {
        id: 19,
        value: 19,
        text: '山梨県'
    },
    {
        id: 20,
        value: 20,
        text: '長野県'
    },
    {
        id: 21,
        value: 21,
        text: '岐阜県'
    },
    {
        id: 22,
        value: 22,
        text: '静岡県'
    },
    {
        id: 23,
        value: 23,
        text: '愛知県'
    },
    {
        id: 24,
        value: 24,
        text: '三重県'
    },
    {
        id: 25,
        value: 25,
        text: '滋賀県'
    },
    {
        id: 26,
        value: 26,
        text: '京都府'
    },
    {
        id: 27,
        value: 27,
        text: '大阪府'
    },
    {
        id: 28,
        value: 28,
        text: '兵庫県'
    },
    {
        id: 29,
        value: 29,
        text: '奈良県'
    },
    {
        id: 30,
        value: 30,
        text: '和歌山県'
    },
    {
        id: 31,
        value: 31,
        text: '鳥取県'
    },
    {
        id: 32,
        value: 32,
        text: '島根県'
    },
    {
        id: 33,
        value: 33,
        text: '岡山県'
    },
    {
        id: 34,
        value: 34,
        text: '広島県'
    },
    {
        id: 35,
        value: 35,
        text: '山口県'
    },
    {
        id: 36,
        value: 36,
        text: '徳島県'
    },
    {
        id: 37,
        value: 37,
        text: '香川県'
    },
    {
        id: 38,
        value: 38,
        text: '愛媛県'
    },
    {
        id: 39,
        value: 39,
        text: '高知県'
    },
    {
        id: 40,
        value: 40,
        text: '福岡県'
    },
    {
        id: 41,
        value: 41,
        text: '佐賀県'
    },
    {
        id: 42,
        value: 42,
        text: '長崎県'
    },
    {
        id: 43,
        value: 43,
        text: '熊本県'
    },
    {
        id: 44,
        value: 44,
        text: '大分県'
    },
    {
        id: 45,
        value: 45,
        text: '宮崎県'
    },
    {
        id: 46,
        value: 46,
        text: '鹿児島県'
    },
    {
        id: 47,
        value: 47,
        text: '沖縄県'
    },

]
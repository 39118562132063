export const archiveViewVariants = {
    hidden: { transform: 'translateX(100%)', opacity: 0 },
    visible: { transform: 'translateX(0%)', opacity: 1 },
    exit: { transform: 'translateX(100%)', opacity: 0 }
}

export const checkoutModalVariants = {
    hidden: { x: '100vw', opacity: 0 },
    visible: { x: 0, opacity: 1 },
    exit: { x: '-100vw', opacity: 0 }
}

export const fadeInVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    transition: { duration: .6, type: "tween", ease: "easeInOut" },
}
import { useStaticQuery, graphql } from "gatsby"

const useFuseArchives = () => {
  const { allArchivesJson } = useStaticQuery(
    graphql`
      query {
        allArchivesJson(sort: { fields: [_id], order: DESC }) {
          nodes {
            _id
            url_id
            title {
              en
              ja
            }
            place {
              en
              ja
              distance
            }
            level {
              en
              ja
            }
            tags
            year
            month {
              en
              ja
            }
            type
            description {
              en
              ja
            }
          }
        }
      }
    `
  )

  return allArchivesJson.nodes
}

export default useFuseArchives

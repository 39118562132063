export const priceList = [
    {
        typeNum: 1,
        name: 'Book_Archive_Both',
        label: { en: 'Book + Archive', ja: '本 ＋ アーカイブサイト' },
        amount: { en: 3600, ja: 3600 },
        price: { en: '36.00', ja: '3600' }
    },
    {
        typeNum: 2,
        name: 'Book_Only',
        label: { en: 'Book', ja: '本' },
        amount: { en: 3000, ja: 3000 },
        price: { en: '30.00', ja: '3000' }
    },
    {
        typeNum: 3,
        name: 'Archive_Only',
        label: { en: 'Archive', ja: 'アーカイブ' },
        amount: { en: 1900, ja: 2000 },
        price: { en: '19.00', ja: '2000' }
    },
]

import { useStaticQuery, graphql } from "gatsby"

const useAllArchivesList = () => {
  const { allArchivesJson } = useStaticQuery(
    graphql`
      query {
        allArchivesJson(sort: { fields: [_id], order: DESC }) {
          nodes {
            _id
            url_id
            title {
              en
              ja
            }
            place {
              en
              ja
              distance
            }
            level {
              en
              ja
            }
            year
            month {
              en
              ja
            }
            type
            onBook
            aspect
            description {
              en
            }
          }
        }
      }  
    `
  )

  return allArchivesJson.nodes
}

export default useAllArchivesList

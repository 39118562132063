import React, { useState, useContext, useRef } from 'react'
import { useIntl } from "gatsby-plugin-intl"
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
// Context
import ArchiveContext from '../../context/archiveContext'
// CSS
import "./css/fuse-search.styl"
import useFuseArchives from '../../hooks/use-fuse-search-archive';
import Fuse from 'fuse.js'


const Fusesearch = ({ fuseFocus, setFuseFocus }) => {
    // Hook
    const intl = useIntl()
    const language = intl.locale
    const [hits, setHits] = useState([])
    const handleClick = () => setFuseFocus(true)
    const handleClickAway = () => setFuseFocus(false)

    // Context
    const currentArchiveState = useContext(ArchiveContext)
    const { isArchiveView, archiveNumber } = currentArchiveState

    // useRef
    const searchInput = useRef()

    // Function
    const clear = () => {
        searchInput.current.value = ""
        setFuseFocus(false)
        setHits([])
    }
    const list = useFuseArchives()

    // Fuse-search
    const handleSearch = ({ currentTarget: { value: input } }) => {
        // const input = searchInput.value
        const options = {
            // isCaseSensitive: false,
            // includeScore: false,
            // shouldSort: true,
            includeMatches: true,
            // findAllMatches: false,
            // minMatchCharLength: 1,
            // location: 0,
            // threshold: 0.6,
            // distance: 100,
            // useExtendedSearch: false,
            // ignoreLocation: false,
            // ignoreFieldNorm: false,
            keys: [
                "title.en",
                "title.ja",
                "description.en",
                "description.ja",
                "place.en",
                "place.ja",
                "month.en",
                "month.ja",
                "level.en",
                "level.ja",
                "year",
                "type",
                "tags",
            ]
        };

        const fuse = new Fuse(list, options);
        setHits(fuse.search(input))
    }

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => {
                handleClickAway()
                clear()
            }}>
            <ul className={"fuse_searchbar " + (fuseFocus ? 'active' : '')}>
                <li className="searchbar">
                    <svg className="icon01" height="21" viewBox="0 0 24 21" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#999" strokeWidth="2"><circle cx="8" cy="8" r="7" /><path d="m14 12 9 8" strokeLinecap="round" /></g></svg>
                    <input
                        onChange={handleSearch}
                        onClick={handleClick}
                        ref={searchInput}
                        placeholder={language === "en" ? "What do you look for?" : "何かお探しですか？"}
                    />
                    <button onClick={clear}><svg viewBox="0 0 83 83" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#ffffff" strokeLinecap="round" strokeWidth="2" transform="matrix(.70710678 .70710678 -.70710678 .70710678 41.5 -38.89844)"><path d="m.151601 56.850283h113.397363" /><path d="m0 56.850283h113.700565" transform="matrix(0 1 -1 0 113.700564 0)" /></g></svg></button>
                </li>
                {fuseFocus && hits.length > 0 && <div className="hit_container">
                    {hits.map(hit => (
                        <li key={hit.refIndex}
                            onClick={() => {
                                isArchiveView[1](true)
                                archiveNumber[1](hit.item._id)
                            }}>
                            <p className="title">{hit.item.title[language]}</p>
                            <p className="place_level">{hit.item.place[language]}{hit.item.level[language] && ` ／ ${hit.item.level[language]}`}</p>
                            <p className="description">{hit.item.description[language]}</p>
                        </li>
                    ))}
                </div>}
            </ul>
        </ClickAwayListener>

    )
}

export default Fusesearch


import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
//Stripe
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './checkoutForm'
import OrderStep from './orderStep'
import './css/payment-book.styl'
import NextBackBtn from './nextBackBtn'
// Framer motion and Variants
import { motion } from "framer-motion"
import { checkoutModalVariants } from '../../asset/variants-framer-motion/variants'
// Price List
import { priceList } from '../../data/price-list'

const { GATSBY_STRIPE_PUBLISHABLE_KEY } = process.env
const promise = loadStripe(`${GATSBY_STRIPE_PUBLISHABLE_KEY}`)

const Payment = ({
    processNum,
    setProcessNum,
    setProductType,
    productType,
    setBookOrderState,
    setIsBookOrdering,
    setIsArchiveOrdering,
    setLoading,
    bookQuantity,
    setArchivePaid,
    setPaidProductType,
    userEmail
}) => {

    const intl = useIntl()
    const language = intl.locale
    const amount =
        productType === 2 ?
            priceList[productType - 1].amount[language] * bookQuantity :
            priceList[productType - 1].amount[language]

    return (
        <div className="paymentBookWrap">
            {productType !== 3 && <OrderStep processNum={processNum} />}
            <ul className='grid'>
                {productType !== 3 && <li className="photo">
                    <iframe
                        src="https://player.vimeo.com/video/461975291?autoplay=1&loop=1&muted=1"
                        frameBorder="0"
                        // width="700"
                        height="220"
                        allow="fullscreen"
                        allowFullScreen></iframe>
                </li>}
                {productType === 3 && <li className="photo">
                    <iframe
                        src="https://player.vimeo.com/video/461991350?autoplay=1&loop=1&muted=1"
                        frameBorder="0"
                        // width="700"
                        height="220"
                        allow="fullscreen"
                        allowFullScreen></iframe>
                </li>}
                <li>
                    <motion.div
                        variants={checkoutModalVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit">
                        <h5>{language === 'ja' ? '[ご注文内容]' : '[Order]'}</h5>
                        <h3 className="product">
                            <div>
                                {priceList[productType - 1].label[language]}　･･･　{priceList[productType - 1].price[language]}
                                <span className="unit">{language === 'ja' ? ' 円' : ' USD'}</span>
                                {productType === 2 && <><span className="price"> x</span> {bookQuantity}</>}
                            </div>
                            <div>
                                <span className="unit">{language === 'ja' ? '合計 ' : 'Total '}</span>
                                {language === 'ja' ? amount : (amount / 100).toFixed(2)}
                                <span className="unit">{language === 'ja' ? ' 円' : ' USD'}</span>
                            </div>
                        </h3>
                        <p>
                            {language === 'ja' ?
                                'カード番号・カード有効期限・CVCを入力し、決済ボタンを押してください。\n※ 2冊以上の本をご要望の方は、本のみのご注文をご利用ください。' :
                                'Enter Card number/Card expire date/CVC/Postal code and push PAY button.'}</p>
                        <Elements stripe={promise}>
                            <CheckoutForm
                                processNum={processNum}
                                setProcessNum={setProcessNum}
                                productType={productType}
                                amount={amount}
                                setBookOrderState={setBookOrderState}
                                setLoading={setLoading}
                                setArchivePaid={setArchivePaid}
                                setPaidProductType={setPaidProductType}
                                userEmail={userEmail} />
                        </Elements>
                        {/* <p className="annotation"></p> */}
                        <NextBackBtn
                            processNum={processNum}
                            setProcessNum={setProcessNum}
                            productType={productType}
                            setProductType={setProductType}
                            setIsBookOrdering={setIsBookOrdering}
                            setIsArchiveOrdering={setIsArchiveOrdering}
                            nextText=''
                            backText={language === 'ja' ? '戻る' : 'Back'}
                            nextRequired={false}
                            backRequired={true} />
                    </motion.div>
                </li>
            </ul>
            {productType === 3 &&
                <div className="caution">
                    <>{language === 'ja' ?
                        'このアーカイブサイトは、以下のブラウザ（最新版）にて動作確認をしております。IEでは動作しませんので、予めご了解ください。' :
                        'This archive app is checked to work on the following browsers.'}<br />
                    Windows - Google Chrome / FireFox、Mac - Google Chrome / FireFox / Safari<br />
                    iOS - Google Chrome / Brave、Android - Google Chrome</>
                </div>
            }
        </div>
    )
}

export default Payment;
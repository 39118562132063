import React, { useState, useEffect } from "react"
import _ from 'lodash'
import Joi from "joi-browser"
import { prefs } from '../../data/shippingPrefs'
import { validate, handleChange, validateProperties, zenhan } from '../../utils/form-functions';
import Input from '../input'
import OrderStep from './orderStep';
import axios from 'axios';
import './css/shippingForm.styl'
import NextBackBtn from './nextBackBtn';
import { motion } from "framer-motion"
import { checkoutModalVariants } from '../../asset/variants-framer-motion/variants'
import Img from "gatsby-image"
import useAllImgs from "../../hooks/use-all-image"

const { GATSBY_FUNCTION_DOMAIN } = process.env

const ShippingForm = ({
    processNum,
    setProcessNum,
    setOrderID,
    setBookOrderState,
    setLoading,
    bookQuantity,
    productType,
    setBookPaid,
    setArchivePaid,
    setPaidProductType,
    userEmail }) => {
    //// Hook
    // Shipping Details
    const [shippingDetail, setShippingDetail] = useState({})

    const orderDetail = {
        testmode: 0, //
        customer_id: 39092, //
        type: 1,
        order_date: "",
        express: 0,
        cre_addr: 1,
        pre_regist_addr: 0,
        addr: { ...shippingDetail },
        detail: [
            { "draft_id": 207678, "quantity": bookQuantity }
        ]
    }

    // useEffect
    useEffect(() => {
        setShippingDetail({ 'email': userEmail })
        if (typeof window !== "undefined") {
            window.history.pushState(null, null)
            window.addEventListener('popstate', function (e) {
                alert('本がまだ発注されておりません。配送先を設定してください。')
            })
        }
    }, [])

    const [errors, setErrors] = useState({})
    const { zip01, zip02, pref, addr01, addr02, tel01, tel02, tel03, tel, name, email } = shippingDetail


    //// Joi Schema ////
    const schema = {
        zip01: Joi.number()
            // .min(3).max(3)
            .required()
            .label(" 郵便番号1 "),
        zip02: Joi.number()
            // .min(4).max(4)
            .required()
            .label(" 郵便番号2 "),
        pref: Joi.number()
            .required()
            .greater(0)
            .label(" 都道府県 "),
        addr01: Joi.string()
            .trim()
            .required()
            .label(" 住所1 "),
        addr02: Joi.string()
            .trim()
            .required()
            .label(" 住所2 "),
        tel01: Joi.number()
            .required()
            .label(" 電話番号 "),
        tel02: Joi.number()
            .required()
            .label(" 電話番号 "),
        tel03: Joi.number()
            .required()
            .label(" 電話番号 "),
        tel: Joi.number()
            .required()
            .label(" 電話番号 "),
        name: Joi.string()
            .trim()
            .required()
            .label(" お名前 "),
        email: Joi.string().email()
            .trim()
            .required()
            .label(" メールアドレス "),
    }

    //// Functions
    const postBookOrder = async (event) => {
        const objJsonStr = JSON.stringify(orderDetail)

        try {
            const result = await fetch(`${GATSBY_FUNCTION_DOMAIN}/post-book-order?objJsonStr=${objJsonStr}`)
                .then(data => data.json())

            const successDate = Buffer.from(result, 'base64').toString();
            setOrderID(JSON.parse(successDate).order_id)
            setBookOrderState('success')
            if (productType === 1) {
                setBookPaid(true)
                setArchivePaid(true)
                setPaidProductType(productType)
            }
            if (productType === 2) {
                // setBookPaid(true)
                setPaidProductType(productType)
            }
            setLoading(false)
        } catch (err) {
            setBookOrderState('error')
            setLoading(false)
        }
    }

    // Record Order_date
    const recordOrderDate = () => {
        let time = new Date()
        let year = String(time.getFullYear())
        let month = addZero(time.getMonth() + 1)
        let date = addZero(time.getDate())
        let hours = addZero(time.getHours())
        let minutes = addZero(time.getMinutes())
        let seconds = addZero(time.getSeconds())
        let order_date = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`

        orderDetail.order_date = order_date
    }

    function addZero(num) {
        return ("0" + num).slice(-2)
    }

    // Addition Telephone Number
    const addTelephoneNum = ({ currentTarget: input }, position) => {
        // validation
        const currentErrors = errors
        const errorMessage = validateProperties(input, schema)
        setErrors({ ...currentErrors, [input.name]: errorMessage })

        // inputs to state
        const trimNum = input.value.trim()
        setShippingDetail({
            ...shippingDetail,
            [input.name]: trimNum,
            ['tel']: `${position === 0 ? trimNum : tel01}${position === 1 ? trimNum : tel02}${position === 2 ? trimNum : tel03}`
        })
    }

    // GET address from ZIP
    const getAddressPostAPI = (e) => {
        e.preventDefault()

        const get = async () => {
            let addressData = {}
            try {
                const data = await axios(`${GATSBY_FUNCTION_DOMAIN}/get-postal-address?zipcode=${zip01}${zip02}`)
                    .then(res => res.data)
                addressData = data
            } catch (err) {
                throw new Error(err)
            }

            if (addressData) {
                const result = prefs.filter(pref => pref.value === Number(addressData[0].prefcode))
                const currentState = shippingDetail
                setShippingDetail({ ...currentState, pref: result[0].value, addr01: addressData[0].address2 + addressData[0].address3 })
            }
        }
        get()
    }

    // Image
    const images = useAllImgs()
    const bookCoverImg = images.find(
        img => img.childImageSharp.fluid.originalName === "img-book.jpg"
    )



    return (
        <>
            <div className="formWrap">
                <div className="shippingFormWrap">
                    <OrderStep processNum={processNum} />
                    <ul className='grid'>
                        <li className="photo"><Img fluid={bookCoverImg.childImageSharp.fluid} /></li>
                        {processNum === 2 &&
                            <motion.li
                                variants={checkoutModalVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit">
                                <form className="shippingForm" action="">
                                    <ul>
                                        <li className="postcode">
                                            <label htmlFor="">郵便番号</label>
                                            <div>
                                                <input type="text" name="zip01"
                                                    onChange={e => handleChange(e, shippingDetail, errors, schema, setShippingDetail, setErrors)}
                                                    placeholder="123" value={zip01 || ""} />{" - "}
                                                <input type="text" name="zip02"
                                                    onChange={e => handleChange(e, shippingDetail, errors, schema, setShippingDetail, setErrors)}
                                                    placeholder="4567" value={zip02 || ""} />
                                                {(errors['zip01'] || errors['zip02']) && (
                                                    <span
                                                        className="error"
                                                        style={{ marginLeft: '.6rem' }}>
                                                        ※郵便番号を半角数字で入力してください。</span>
                                                )}
                                                {(!errors['zip01'] && !errors['zip02']) && (
                                                    <button
                                                        className="zipAPI"
                                                        onClick={getAddressPostAPI}
                                                        disabled={!((zip01 && zip01.length) === 3 && (zip02 && zip02.length) === 4)}>
                                                        住所検索</button>
                                                )}
                                            </div>
                                        </li>
                                        <li className="prefecture">
                                            <label htmlFor="">都道府県</label>
                                            <select type="text" name="pref"
                                                onChange={e => {
                                                    handleChange(e, shippingDetail, errors, schema, setShippingDetail, setErrors)
                                                    // checkPrefecture()
                                                }}
                                                value={pref || ""}>
                                                {prefs.map(pre => (<option key={pre.id} value={pre.value}>{pre.text}</option>))}
                                            </select>
                                            {errors['pref'] && (
                                                <div className="error">※都道府県を選択してください。</div>
                                            )}
                                        </li>
                                        <li>
                                            <Input
                                                name={'addr01'}
                                                label={'ご住所'}
                                                shippingDetail={shippingDetail}
                                                errors={errors}
                                                schema={schema}
                                                setShippingDetail={setShippingDetail}
                                                setErrors={setErrors}
                                                placeholder={"〇〇市〇〇町〇丁目"}
                                                errorMessage={'※住所を入力してください。'} />
                                        </li>
                                        <li>
                                            <Input
                                                name={'addr02'}
                                                label={'続きのご住所'}
                                                shippingDetail={shippingDetail}
                                                errors={errors}
                                                schema={schema}
                                                setShippingDetail={setShippingDetail}
                                                setErrors={setErrors}
                                                placeholder={"〇-〇　〇〇ビル　〇〇〇号室"}
                                                errorMessage={'※上記に続く住所を入力してください。'} />
                                        </li>
                                        <li className="tel">
                                            <label htmlFor="">電話番号</label>
                                            <div>
                                                <input type="text" name="tel01"
                                                    onChange={e => { addTelephoneNum(e, 0) }}
                                                    placeholder="" value={tel01 || ""} />{" - "}
                                                <input type="text" name="tel02"
                                                    onChange={e => { addTelephoneNum(e, 1) }}
                                                    placeholder="" value={tel02 || ""} />{" - "}
                                                <input type="text" name="tel03"
                                                    onChange={e => { addTelephoneNum(e, 2) }}
                                                    placeholder="" value={tel03 || ""} />
                                                {(errors['tel01'] || errors['tel02'] || errors['tel03']) &&
                                                    (<span className="error" style={{ marginLeft: '.6rem' }}>※電話番号を半角数字で入力してください。</span>)}
                                            </div>
                                        </li>
                                        <li>
                                            <Input
                                                name={'name'}
                                                label={'お名前'}
                                                shippingDetail={shippingDetail}
                                                errors={errors}
                                                schema={schema}
                                                setShippingDetail={setShippingDetail}
                                                setErrors={setErrors}
                                                placeholder={"田中 花子"}
                                                errorMessage={'※お名前を入力してください。'} />
                                        </li>
                                        <li>
                                            <Input
                                                name={'email'}
                                                type={'email'}
                                                label={'Emailアドレス'}
                                                shippingDetail={shippingDetail}
                                                errors={errors}
                                                schema={schema}
                                                setShippingDetail={setShippingDetail}
                                                setErrors={setErrors}
                                                placeholder={"address@example.com"}
                                                errorMessage={'※メールアドレスを入力してください。'} />
                                        </li>
                                    </ul>
                                </form>
                                {/* <ul>
                                {errorsArray.map(err => (
                                    (err.title === 'email' && <li>※メールアドレスを入力してください。</li>) +
                                    (err.title === 'name' && <li>※お名前を入力してください。</li>)
                                ))}

                            </ul> */}
                                <NextBackBtn
                                    processNum={processNum}
                                    setProcessNum={setProcessNum}
                                    nextText='確認'
                                    backText=''
                                    nextRequired={true}
                                    backRequired={false}
                                    disabled={validate(shippingDetail, schema)} />
                            </motion.li>

                        }

                        {processNum === 3 &&
                            <motion.li
                                className="shippingConfirm"
                                variants={checkoutModalVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit">
                                <p>配達先が正しいことを確認して、確定してください。</p>
                                <ul className="list">
                                    <li><div>郵便番号</div><div>{zenhan(zip01)}{' - '}{zenhan(zip02)}</div></li>
                                    <li><div>都道府県</div><div>{prefs[pref].text}</div></li>
                                    <li><div>住所</div><div>{addr01}</div></li>
                                    <li><div>住所続き</div><div>{addr02}</div></li>
                                    <li><div>電話番号</div><div>{tel}</div></li>
                                    <li><div>お名前</div><div>{name}</div></li>
                                    <li><div>Email</div><div>{email}</div></li>
                                </ul>
                                <ul className="nextBackBtn">
                                    <li className={"next"}
                                        onClick={() => {
                                            setProcessNum(processNum + 1)
                                            recordOrderDate()
                                            postBookOrder()
                                            setLoading(true)
                                        }}>
                                        確定
                                    </li>
                                    <li className="back"
                                        onClick={() => setProcessNum(processNum - 1)}>
                                        戻る
                                    </li>
                                </ul>
                            </motion.li>}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default ShippingForm;
import { useStaticQuery, graphql } from "gatsby"

const useAllArchiveImage = () => {
  const { allFile } = useStaticQuery(graphql`
  query {
    allFile(
      filter: { relativeDirectory: { eq: "archive" } }
      sort: { fields: [relativePath], order: DESC }
    ) {
      nodes {
        childImageSharp {
          id
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
   
  `)

  return allFile.nodes
}

export default useAllArchiveImage

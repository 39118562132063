import React from 'react';
import Img from "gatsby-image"
import { useIntl } from 'gatsby-plugin-intl'
import { motion } from 'framer-motion'
import './css/about.styl'
import useArchiveAboutImage from '../../hooks/use-archive-about-image';
import { fadeInVariants } from '../../asset/variants-framer-motion/variants';

const About = () => {
    const imgs = useArchiveAboutImage()
    const intl = useIntl()
    const language = intl.locale

    return (
        <>
            {language === "ja" && <motion.div className="about"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
                transition={{ duration: .6, type: "tween" }}
            >
                <h2>放射線像の撮像方法について</h2>
                <div>放射線像（オートラジオグラフ）は、オートラジオグラフィーという手法で作成されます。放射線像を撮像する設備は、現在日本を含めた世界中の主要大学や研究機関が持っており、普段の生理学・生化学・分子生物学などの研究でサンプル中の放射能の分布を感度よく迅速に観察するために使用されています。撮影された像は、研究論文や学会発表でも頻繁に利用されています。1987年富士フィルム工業と化成オプトニクスが開発した放射線に感度を有するイメージングプレートはそれまで研究で使われてきた医療用のレントゲンフィルムの約100～1000倍の感度を持つと言われ、得られる像の解像度は低いものの、短時間で撮像することができます。<br /><br />
                    <ul>
                        <li>{<><Img fluid={imgs[0].childImageSharp.fluid} />
                            <figcaption>イメージングプレート</figcaption></>}</li>
                        <li>{<><Img fluid={imgs[1].childImageSharp.fluid} />
                            <figcaption>イメージングプレートをBASにセッティングする</figcaption></>}</li>
                    </ul><br />
            撮像のプロセスは、放射能汚染されたサンプルをイメージングプレートに乗せ、完全な暗所で一定期間静置させた後、BASと呼ばれる装置で読み取ります。読み取った画像のコントラストを丁寧に調整することで、放射能の分布が浮かび上がってきます。放射線像の中で、白く写った部分が放射性物質が発する放射線です。より白く写った部分はより強く放射能汚染されていることを示しています。原理としては、病院で使われるレントゲン写真と同じ現象です。<br /><br />
            放射線像を通して、汚染を受けたサンプルの中で、放射性物質がどのように分布し、どの部分がより汚染が強いのか判断することができます。またその汚染形態を把握することで、汚染がどのようにして起ったのか、どのような経路を辿ってそのサンプルを汚染させたのか推測することができます。例えば、サンプルの内部が汚染しているのか外部が汚染しているのか、放射性物質が水に溶けた形で存在しているのか、あるいは放射性粒子として付着しているのかといったことがひと目で判別可能になります。<br /><br />
            放射線像を見る際に注意する点として、別々に撮影された像はそれぞれにコントラストを調整しているので、それらを並べてどちらが汚染が強いと判断することはできません。今回掲載する放射線像には、記録を残しているものに限り、それぞれのサンプルでサーベイメータを使って計測したβ（ベータ）線の放射線量「cpm」とゲルマニウム半導体検出器などで測定したCs-134とCs-137を合計した放射能測定値「ベクレル (Bq/kg)」を併記しています。この数値がそのサンプルの汚染の強さを表していますので、あわせてご覧ださい。サーベイメータによる測定値 cpm は、通常バックグラウンド(汚染物がない状態)の2倍の数値が検出された場合、そこが放射能汚染されていると判断されます。今回サーベイメータよる測定を行った場所は、バックグラウンドが 25-40 cpmです。また、ベクレル数の参考値として、天然の放射性物質 K40は、白米で 33 Bq/kg、乾燥昆布で 1600 Bq/kg、人体で 66 Bq/kg（水分を除いた乾燥重量で 165 Bq/kg）程度であり、われわれがこれまで採集してきたサンプルがどれだけ強く放射能汚染されているかご理解いただけると思います。<br /><br />
            放射線像を見る際に注意する点として、別々に撮影された像はそれぞれの放射線量に応じて露光時間を決めているため、それらを並べてどちらが汚染が強いと判断することはできません。今回掲載する放射線像には、記録を残しているものに限り、それぞれのサンプルでサーベイメータを使って計測したβ（ベータ）線の放射線量「cpm」とゲルマニウム半導体検出器などで測定した放射能測定値「ベクレル (Bq/kg)」を併記しています。この数値がそのサンプルの汚染の強さを表していますので、あわせてご覧ださい。サーベイメータによる測定値 cpm は、通常バックグラウンド（汚染物がない状態）の2倍の数値が検出された場合、そこが放射能汚染されていると判断されます。今回サーベイメータよる測定を行った場所は、バックグラウンドが 40～60 cpmです。また、ベクレル数の参考値として、天然の放射性物質 K40は、白米で 33 Bq/kg、乾燥昆布で 1600 Bq/kg、人体で 66 Bq/kg（水分を除いた乾燥重量で 165 Bq/kg）程度であり、われわれがこれまで採集してきたサンプルがどれだけ強く放射能汚染されているかご理解いただけると思います。
                </div>
            </motion.div>}
            {language === "en" && <motion.div className="about"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
                transition={{ duration: .6, type: "tween" }}
            >
                <h2>How to take Autoradiograph</h2>
                <div>The radiographic images on display were created by a method called “autoradiography”. Major universities and research facilities in Japan and throughout the world have autoradiography equipment, which is commonly used in biological, biochemical, and microbiological research to quickly view, with high sensitivity, the distribution of radioactive substances in a sample. These images are frequently used in research papers and in conference presentations. The radiation-sensitive imaging plate developed in 1987 by Fuji Film Industries and Kasei Optics is approximately 100 to 1000 times more sensitive than x-ray film<br /><br />
                    <ul>
                        <li>{<><Img fluid={imgs[0].childImageSharp.fluid} />
                            <figcaption>Imaging Plate</figcaption></>}</li>
                        <li>{<><Img fluid={imgs[1].childImageSharp.fluid} />
                            <figcaption>Setting Imaging Plate to BAS</figcaption></>}</li>
                    </ul><br />
                    The imaging process involves placing a radioactive sample on the imaging plate for a given period, after which the imaging plate is read by a device called a BAS. The contrast of the read image is carefully adjusted, whereby the distribution of radioactive substances gradually becomes apparent. Radiation emitted by radioactive particles appears as white portions in the radiographic image. The brighter the portion is, the stronger the radioactive contamination is. An autoradiographic image exhibits the same phenomenon as an x-ray image taken at a hospital, where bones, which block radiation, appear white and portions through which the radiation passes without being blocked appear black<br /><br />
                    When viewing a radiographic image, one point to keep in mind is that since each image is individually adjusted for contrast, the images cannot be compared with one another to determine which indicates greater contamination. In this exhibition, records concerning specimens indicate β radiation levels in both “cpm” measured using a survey meter in the sampling of each specimen and in “becquerels” (Bq/kg) measured using a gerimanium semiconductor detector. These values express the degree of contamination of the specimens and therefore, I hope that you will keep these values in mind while viewing the images. For example, as a reference for measurements in becquerels, naturally occurring radioactive potassium K40 is on the order of 33Bq/kg for white rice, 1600Bq/kg for dried kelp, and 66Bq/kg (dry weight, excluding water content: 165Bq/kg) for the human body. Further, an area is determined to be radioactively contaminated if the measured cpm value is twice the usual background level, in this case, 25 to 40 cpm. Therefore, when comparing the measured values with these reference values, I think that you will come to appreciate the extent to which the specimens on display have been contaminated by radioactive particles
                </div>
            </motion.div>}
        </>
    )
}

export default About;
import { useStaticQuery, graphql } from "gatsby"

const useSingleArchiveDetail = (id) => {
  const { allArchivesJson } = useStaticQuery(
    graphql`
      query{
        allArchivesJson(
          sort: { fields: [_id], order: DESC }
        ){
          nodes {
            _id
            url_id
            title {
              en
              ja
            }
            place {
              en
              ja
              distance
            }
            level{
              en
              ja
            }
            year
            month {
              en
              ja
            }
            type
            aspect
            description {
              en
              ja
            }
          }
        }
      }
    `
  )

  return allArchivesJson.nodes.filter(item => item._id === id)
}

export default useSingleArchiveDetail

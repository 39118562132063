import React from 'react';
import './css/orderStep.styl'

const OrderStep = ({ processNum }) => {
    return (
        <ul className="orderStep">
            <li className={(processNum === 0 || processNum === 1) ? "active" : ''}>お支払い</li>
            <li>・・<span className="pc">・</span></li>
            <li className={(processNum === 0 || processNum === 2) ? "active" : ''}>配達先設定</li>
            <li>・・<span className="pc">・</span></li>
            <li className={(processNum === 0 || processNum === 3) ? "active" : ''}>配達先確定</li>
        </ul>
    );
};

export default OrderStep;
import React from 'react'
import "./css/loading.styl"

const Loading = () => {
    return (
        <div className="wrap_spinner">
            <div className="loadingio-spinner-eclipse-gq2vgiow2cc">
                <div className="ldio-ypvyi11jrk">
                    <div></div>
                </div>
            </div>
        </div>

    )
};

export default Loading
import { useStaticQuery, graphql } from "gatsby"

const AllBookPages = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "book" } }
        sort: { fields: [relativePath], order: ASC }
      ) {
        nodes {
          childImageSharp {
            id
            fluid(maxWidth: 1600) {
              src
            }
          }
        }
      }
    }
  `) //(quality: 90)

  return allFile.nodes
}

export default AllBookPages

import React from 'react';
import './css/nextBackBtn.styl'

const NextBackBtn = ({
    processNum,
    setProcessNum,
    productType,
    setProductType,
    setIsBookOrdering,
    setIsArchiveOrdering,
    nextText,
    backText,
    nextRequired,
    backRequired,
    disabled }) => {

    return (
        <ul className="nextBackBtn">
            {nextRequired &&
                <li className={"next" + (!!disabled ? " disabled" : "")}
                    onClick={() => setProcessNum(processNum + 1)}>
                    <button disabled={!!disabled} >{nextText}</button>
                </li>}
            {backRequired && (productType !== 3) &&
                <li className="back"
                    onClick={
                        (processNum === 0 || processNum === 4) ?
                            () => {
                                setProductType(0)
                                setIsBookOrdering(false)
                            } :
                            () => setProcessNum(processNum - 1)}>
                    {backText}
                </li>}
            {backRequired && productType === 3 &&
                <li className="back"
                    onClick={() => {
                        setProductType(0)
                        setIsArchiveOrdering(false)
                    }}>
                    {backText}
                </li>}
        </ul>
    )
}

export default NextBackBtn
import { useStaticQuery, graphql } from "gatsby"

const useAllArchivesNav = () => {
  const { allAllArchivesNavJson } = useStaticQuery(
    graphql`
        query {
          allAllArchivesNavJson{
            nodes{
              nav_title {
                en
                ja
              }
             saved {
                en
                ja
              }
              plant {
                en
                ja
              }
              animal {
                en
                ja
              }
              daily {
                en
                ja
              }
              compare {
                en
                ja
              }
              book {
                en
                ja
              }
              noBook {
                en
                ja
              }
              about {
                en
                ja
              }
              epilogue {
                en
                ja
              }
              noResult {
                en
                ja
              }
              noSavedArchive {
                en
                ja
              }
            }
          }
        }
                  
    `
  )

  return allAllArchivesNavJson.nodes
}

export default useAllArchivesNav

import React, { useState, useEffect } from "react"
import { useIntl } from 'gatsby-plugin-intl'
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js"

import './css/checkoutForm.styl'

const { GATSBY_FUNCTION_DOMAIN } = process.env
const { GATSBY_STRIPE_PUBLISHABLE_KEY } = process.env

const CheckoutForm = ({
    processNum,
    setProcessNum,
    productType,
    amount,
    setBookOrderState,
    setLoading,
    setArchivePaid,
    setPaidProductType,
    userEmail }) => {

    // Language
    const intl = useIntl()
    const language = intl.locale

    // Button letter
    const buttonLetter = language === 'ja' ? "決済" : 'PAY'

    // Email escape 'Undefined String'
    const email = userEmail === undefined ? '' : userEmail

    const [succeeded, setSucceeded] = useState(false)
    const [error, setError] = useState(null)
    const [processing, setProcessing] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [clientSecret, setClientSecret] = useState('')
    const stripe = useStripe()
    const elements = useElements()

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        const fetchClientSecret = async () => {
            try {
                await fetch(`${GATSBY_FUNCTION_DOMAIN}/get-stripe-client-secret?productType=${productType}&amount=${amount}&userEmail=${email}&lang=${language}`)
                    .then(data => data.json())
                    .then(data => { setClientSecret(data.client_secret) })
            } catch (err) {
                console.log('err:', err)
            }
        }
        fetchClientSecret()
    }, [])

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    }

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty)
        setError(event.error ? event.error.message : "")
    }

    const handleSubmit = async ev => {
        ev.preventDefault()
        setProcessing(true)
        if (productType === 3) setLoading(true)
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: ev.target.name.value
                }
            }
        })
        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`)
            setProcessing(false)
            if (productType === 3) {
                setBookOrderState('error')
                setLoading(false)
            }
        } else {
            setError(null)
            setProcessing(false)
            setSucceeded(true)
            setProcessNum(processNum + 1)
            if (productType === 3) {
                setBookOrderState('success')
                setArchivePaid(true)
                setLoading(false)
                setPaidProductType(productType)
            }
        }
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
            <button
                disabled={processing || disabled || succeeded}
                id="submit"
            >
                <span id="button-text">
                    {processing ? (
                        <div className="spinner" id="spinner"></div>
                    ) : (
                            buttonLetter
                        )}
                </span>
            </button>
            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
            {/* Show a success message upon completion */}
            <p className={succeeded ? "result-message" : "result-message hidden"}>
                Payment succeeded, see the result in your
        <a
                    href={`https://dashboard.stripe.com/test/payments`}
                >
                    {" "}
          Stripe dashboard.
        </a> Refresh the page to pay again.
      </p>
        </form>
    )
}

export default CheckoutForm
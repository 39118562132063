import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import _ from 'lodash';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
//Components
import Card from './card'
// Auth0
import { login, logout, isAuthenticated, getProfile } from "../../utils/auth"
import "./css/all-archives.styl"
// Hooks
import useAllArchiveImage from '../../hooks/use-all-archive-image'
import useAllArchivesList from '../../hooks/use-all-archive-list'
import useAllArchivesNav from '../../hooks/use-all-archive-nav';
import getArrayObjConcat from '../../utils/get-arrayObject-concat'
// Context
import ArchiveContext from '../../context/archiveContext'
// CSS
import './css/all-archives.styl'
import './css/card.styl'
// Fuse.js
import Fusesearch from './fuse-search';
import About from './about';
import Epilogue from './epilogue';


const AllArchives = ({
    savedArchives,
    setSavedArchives,
    setIsBookOrdering,
    setProductType,
    isBookPaid,
    setIsAskingBookPageSave
}) => {
    const intl = useIntl()
    const language = intl.locale
    const list = useAllArchivesList()
    const images = useAllArchiveImage()
    const items = getArrayObjConcat(list, images)
    const { nav_title, saved, plant, animal, daily, compare, book, noBook, threeD, about, epilogue, noResult, noSavedArchive } = useAllArchivesNav()[0]
    const initialYears = ["2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011"]
    const initialTypes = ["plant", "animal", "daily", "compare", "3d", "book", "noBook"]
    const totalYearsNum = initialYears.length
    const totalTypesNum = initialTypes.length

    // Context
    const currentArchiveState = useContext(ArchiveContext)
    const { isArchiveView, archiveNumber } = currentArchiveState

    // Effect : Prevent Scroll when archive viewing
    // useEffect(() => {
    //     isArchiveView[0] ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset'
    // }, [isArchiveView[0]])

    // Hook
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [unSelectYears, setUnSelectYears] = useState([...initialYears])
    const [unSelectTypes, setUnSelectTypes] = useState([...initialTypes])
    const [isSavedSort, setIsSavedSort] = useState(false)
    const [viewState, setViewState] = useState('archive')
    const [fuseFocus, setFuseFocus] = useState(false)
    const [isBookSelecting, setIsBookSelecting] = useState(false)
    const [onBook, setOnBook] = useState()
    const [isCapEnSelect, setIsCapEnSelect] = useState(false)
    const [withCapEn, setWithCapEn] = useState()

    const selectedTypes = _.difference(initialTypes, unSelectTypes)
    const selectedYears = _.difference(initialYears, unSelectYears)

    const handleClickAway = () => setIsMenuOpen(false)

    // Functions
    const handleYears = (year) => {
        const currentYears = unSelectYears
        if (!currentYears.includes(year)) {
            const pushedYear = [...currentYears, year]
            return setUnSelectYears([...pushedYear])
        }
        const pulledYear = _.pull(currentYears, year)
        if (pulledYear.length === 0) {
            const resetYear = [...initialYears]
            return setUnSelectYears([...resetYear])
        }
        setUnSelectYears([...pulledYear])
    }

    const handleTypes = (type) => {
        const currentTypes = initialTypes
        if (!unSelectTypes.includes(type)) {
            const pushedTypes = [...unSelectTypes, type]
            return setUnSelectTypes([...pushedTypes])
        }
        const pulledTypes = _.pull(currentTypes, type)
        setUnSelectTypes([...pulledTypes])
    }

    const handleOnBook = (params) => {
        if (params === 'onBook') {
            setIsBookSelecting(true)
            setOnBook(true)
        }
        else if (params === 'notOnBook') {
            setIsBookSelecting(true)
            setOnBook(false)
        }
        else setIsBookSelecting(false)
    }

    const handleWithCapEn = (params) => {
        if (params === 'withCapEn') {
            setIsCapEnSelect(true)
            setWithCapEn(true)
        }
        else if (params === 'withoutCapEn') {
            setIsCapEnSelect(true)
            setWithCapEn(false)
        }
        else setIsCapEnSelect(false)
    }

    const selectedItems = items
        .filter(item => unSelectYears.length !== initialYears.length ? !unSelectYears.includes(item.year) : item)
        .filter(item => unSelectTypes.length !== initialTypes.length ? _.intersection(selectedTypes, item.type).length > 0 : item)
        .filter(item => isBookSelecting ? item.onBook === onBook : item)
        .filter(item => isCapEnSelect ? !!item.description['en'] === withCapEn : item)
        .filter(item => isSavedSort ? savedArchives.includes(item._id) : item)

    const noResultWithSort = () => !isSavedSort && !selectedItems.length
    const noResultWithSortInSaved = () => isSavedSort && !selectedItems.length


    const handleSaved = () => {
        setIsSavedSort(!isSavedSort)
    }

    const addClassSelected = (type) => {
        return (!unSelectTypes.includes(type) ? 'selected' : '')
    }

    const isAnyArchiveSaved = () => {
        return savedArchives.length > 0
    }

    const convertType = (type) => {
        let selectType = ''
        switch (type) {
            case "plant":
                selectType = plant[language]
                break;
            case "animal":
                selectType = animal[language]
                break;
            case "daily":
                selectType = daily[language]
                break;
            case "compare":
                selectType = compare[language]
                break;
            case "3d":
                selectType = '3D'
                break;
            case "book":
                selectType = book[language]
                break;
            case "noBook":
                selectType = noBook[language]
                break;
        }
        return selectType
    }

    const lessSelectedYears = () => {
        const n = 15
        if (selectedYears.length > n) return _.takeRight(selectedYears, n)
        return selectedYears
    }

    return (
        <>
            <section className="allArchive_content">
                {/* <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}> */}
                <nav className={(!isMenuOpen ? 'menuClose' : '') + " allArchive_sideMenu"}>
                    <ul>
                        <div className="sticky-top" onClick={() => { setViewState('archive') }}>
                            <li onClick={() => { handleSaved() }} className={isSavedSort ? 'selected' : ''}>
                                <svg className="saved" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#fff" strokeLinecap="round" transform="matrix(.70710678 -.70710678 .70710678 .70710678 3.553798 10.154777)"><path d="m.726733.058098.692389 6.064971" /><path d="m1.419122 6.123069 12.74264-1.822331" /></g></svg>
                                {saved[language]}
                            </li>
                            <li onClick={() => handleTypes('plant')} className={addClassSelected('plant')}>
                                <svg viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#fff" transform="translate(5 2)"><path d="m3 14c0-6.40677966.64864865-12.33898305 8-14" strokeLinecap="round" /><path d="m11 .01939563c-3.10869565 2.60693235-1.19565217 4.58177041-1.67391304 7.18870276-.71739131 2.60693236-1.91304348 3.79190161-4.54347826 3.79190161-2.63043479 0-4.7826087-2.60693235-4.7826087-4.97687086 0-2.21511982 3.3554109-6.0879222 10.1538462-6.00373351.4751547.0058841.3306462-.0464456.8461538 0z" strokeLinejoin="round" /></g></svg>
                                {plant[language]}
                            </li>
                            <li onClick={() => handleTypes('animal')} className={addClassSelected('animal')}>
                                <svg viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" transform="translate(4 1)"><path d="m14 8.74745338c-2.6961061-12.35750804-15.88553081-5.33122962-13.77166564 1.98360342.42048116 1.4506079 1.44365872 2.9123744 3.27106053 4.2361645.80773885.2961794 1.88472399-1.4808972 1.34623142-2.6656149-3.23095542.5923589-3.76944799-.5923588-4.57718684-1.7770765 1.07698514-2.66561479 2.96170913-3.25797364 5.3849257-2.07325595 1.34623142.59235885 6.19266453 1.77707655 8.34663483.29617943z" stroke="#fff" /><path d="m10 3c-1.45306078 1.76086957-2.74359214 2.77173913-1.49811147 6" stroke="#fff" strokeLinecap="round" /><circle cx="11.5" cy="6.5" fill="#fff" r="1" /><path d="m9 2c-.92592593-1.62962963-2.59259259-2.26337449-5-1.90123457 1.66666667.27160494 1.94444444.81481482 2.22222222 1.90123457" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" /></g></svg>
                                {animal[language]}
                            </li>
                            <li onClick={() => handleTypes('daily')} className={addClassSelected('daily')}>
                                <svg viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" transform="translate(-1 -3)"><path d="m19 14h-16v1c2.18181818 1 5.33333333 2 7.7575758 2 2.4242424 0 5.5757575-.75 8.2424242-2 0-.3333333 0-.6666667 0-1z" /><path d="m12.5 10 .5-5h-4l.5 5" /><path d="m18 14c0-5.33333333-3.1818182-7.51515152-5-8" /><path d="m9 14c0-5.33333333-3.18181818-7.51515152-5-8" transform="matrix(-1 0 0 1 13 0)" /><path d="m7 7v3" transform="matrix(-1 0 0 1 14 0)" /><path d="m15 7v3" transform="matrix(-1 0 0 1 30 0)" /></g></svg>
                                {daily[language]}
                            </li>
                            <li onClick={() => handleTypes('compare')} className={addClassSelected('compare')}>
                                <svg className="comparison" viewBox="0 0 24 14" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#fff" transform="translate(1 1.25)"><g strokeLinecap="round" strokeLinejoin="round"><path d="m8.64285714 7.07142857-4.13533834-6.28571428-3.72180451 6.28571428" /><path d="m3.142857 0 16.107143 2.553571" /><path d="m0 7.267857h9.428571" /></g><path d="m0 7.07142857c0 4.19047623 9.42857143 4.19047623 9.42857143 0" /><path d="m12.571429 8.839286h9.428571" strokeLinecap="round" strokeLinejoin="round" /><path d="m21.2142857 8.64285714-4.1353383-6.28571428-3.7218045 6.28571428" strokeLinejoin="round" /><path d="m12.5714286 8.64285714c0 4.19047616 9.4285714 4.19047616 9.4285714 0" /></g></svg>
                                {compare[language]}
                            </li>
                            <li onClick={() => handleTypes('3d')} className={addClassSelected('3d')}>
                                <svg viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" transform="translate(4 1.25)"><path d="m0 4.18258427v5.84269663c0 .5842697 0 .5842697.28205128.8764045s3.94871795 2.3370786 4.51282051 2.6292135c.56410257.2921348.56410257.2921348 1.12820513 0 .56410257-.2921349 5.51282048-2.3370787 5.79487178-2.6292135s.2820513-.2921348.2820513-.5842697c0-.2921348 0-5.55056177 0-6.13483143s0-.58426966-.5641026-.87640449c-.5641025-.29213484-3.94871791-2.04494382-4.51282048-2.33707866-.56410256-.29213483-.56410256-.29213483-1.12820513 0-.56410256.29213484-4.94871794 2.33707866-5.51282051 2.62921349-.28205128.29213483-.28205128 0-.28205128.58426966z" /><path d="m2 4.75 4 2v5" /><path d="m6 6.75 6-3" /></g></svg>
                                3D
                            </li>
                            {language === 'ja' && <li>
                                <select
                                    type="text"
                                    name="book-select"
                                    className={"book-select" + ((isBookSelecting) ? ' selected' : '')}
                                    onChange={({ currentTarget: input }) => handleOnBook(input.value)}
                                    value={!isBookSelecting && "unselecteBook" || onBook && 'onBook' || !onBook && 'notOnBook'}>
                                    <option value='unselectBook'>▾　本掲載</option>
                                    <option value='onBook'>本 に 掲 載</option>
                                    <option value='notOnBook'>本に非掲載</option>
                                </select>
                            </li>}
                            {language === 'en' && <li>
                                <select
                                    type="text"
                                    name="cap-en-select"
                                    className={"book-select" + ((isCapEnSelect) ? ' selected' : '')}
                                    onChange={({ currentTarget: input }) => handleWithCapEn(input.value)}
                                    value={!isCapEnSelect && "unselecteCap" || withCapEn && 'withCapEn' || !withCapEn && 'withoutCapEn'}>
                                    <option value='unselectCap'>▾　Description</option>
                                    <option value='withCapEn'>with Description</option>
                                    <option value='withoutCapEn'>without Desc.</option>
                                </select>
                            </li>}
                            <li className="years" onClick={() => { setViewState('archive') }}>
                                {initialYears.map(year =>
                                    <div
                                        className={!unSelectYears.includes(year) ? 'selected' : ''}
                                        onClick={() => handleYears(year)}
                                        key={year}>{year}
                                    </div>
                                )}
                            </li>
                        </div>
                        <li>
                            <div className="bottom-sticky">
                                <div className={viewState === 'archive' ? 'active' : ''}
                                    onClick={() => { setViewState('archive') }}>{nav_title[language]}</div>
                                {<div className=''
                                    onClick={() => { setIsAskingBookPageSave(true) }}>{language === 'ja' ? '本を読む' : 'Read book'}</div>}
                                <div className={viewState === 'about' ? 'active' : ''}
                                    onClick={() => { setViewState('about') }}>{about[language]}</div>
                                {<div className={viewState === 'epilogue' ? 'active' : ''}
                                    onClick={() => { setViewState('epilogue') }}>{epilogue[language]}</div>}
                                {/* {language === 'ja' && <div className='book_purchase'
                                    onClick={() => {
                                        setIsBookOrdering(true)
                                        setProductType(2)
                                    }}><span>本のみ購入</span></div>} */}
                            </div>
                        </li>

                    </ul>
                    <div className="archiveMenuButton">
                        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <svg height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg"><g fill="#fff" fillRule="evenodd"><rect height="10" rx="2" width="10" /><rect height="10" rx="2" width="10" x="12" /><rect height="10" rx="2" width="10" y="12" /><rect height="10" rx="2" width="10" x="12" y="12" /></g></svg>
                        </button>
                    </div>
                </nav >
                {/* </ClickAwayListener> */}
                <div className="main" onTouchStart={() => setIsMenuOpen(false)}>
                    {viewState === 'archive' &&
                        <>
                            <Fusesearch fuseFocus={fuseFocus} setFuseFocus={setFuseFocus} />
                            {!fuseFocus && (!!isSavedSort || !!selectedTypes.length || !!selectedYears.length || isBookSelecting || isCapEnSelect) && <ul className="filter">
                                {isSavedSort && <li>{saved[language]}</li>}
                                {isBookSelecting && onBook && <li>{book[language]}</li>}
                                {isBookSelecting && !onBook && <li>{noBook[language]}</li>}
                                {isCapEnSelect && withCapEn && <li>with Description</li>}
                                {isCapEnSelect && !withCapEn && <li>without Description</li>}
                                {selectedTypes.map(type => <li key={type}>{convertType(type)}</li>)}
                                {lessSelectedYears().map(year => <li className="year" key={year}>{year}</li>)}
                                <li className="delete" onClick={() => {
                                    setIsSavedSort(false)
                                    setUnSelectTypes([...initialTypes])
                                    setUnSelectYears([...initialYears])
                                    setIsBookSelecting(false)
                                    setIsCapEnSelect(false)
                                }}>
                                    <button className="delete"><svg viewBox="0 0 83 83" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="#979797" strokeLinecap="round" strokeWidth="2" transform="matrix(.70710678 .70710678 -.70710678 .70710678 41.5 -38.89844)"><path d="m.151601 56.850283h113.397363" /><path d="m0 56.850283h113.700565" transform="matrix(0 1 -1 0 113.700564 0)" /></g></svg></button>
                                </li>
                            </ul>}
                            <ul className="all_archiveWrap">
                                <Card items={selectedItems} />
                            </ul>
                            {noResultWithSort() && <div className="noResult">{noResult[language]}</div>}
                            {noResultWithSortInSaved() && <div className="noResult">{noSavedArchive[language]}</div>}
                        </>
                    }
                    {viewState === 'about' && <About />}
                    {viewState === 'epilogue' && <Epilogue />}
                </div>
            </section >
        </>
    );
}

export default AllArchives;
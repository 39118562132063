import { useStaticQuery, graphql } from "gatsby"

const useArchiveAboutImage = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          name: { in: ["img-about01", "img-about02"] }
        }
        sort: { fields: [relativePath], order: DESC }
      ) {
        nodes {
          childImageSharp {
            id
            fluid(maxWidth: 1654) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return allFile.nodes
}

export default useArchiveAboutImage

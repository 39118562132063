import React from 'react';
import { useIntl } from "gatsby-plugin-intl"
import './css/cv-section.styl'
import _ from "lodash"
import useLandingPageFeatures from '../../hooks/use-landingPage-features'
// Auth0
import { login, logout, isAuthenticated, getProfile, signup } from "../../utils/auth"
import Language from '../language';

const CvSection = ({
    items,
    isArchivePaid,
    isBookPaid,
    setProductType,
    setIsBookOrdering,
    setIsArchiveOrdering }) => {
    const intl = useIntl()
    const language = intl.locale
    const showButtonTypes = [isArchivePaid ? '' : 'archive', (isBookPaid || language === 'en') ? '' : 'book']
    const features = useLandingPageFeatures()[0]

    return (
        <>
            {language === 'ja' && <section className={'cv_top ' +
                (showButtonTypes.length === 0 ? 'none' : '') +
                (showButtonTypes.length === 1 ? 'single' : '') +
                (showButtonTypes.length === 2 ? 'double' : '')} >
                {items.map(item => (
                    (showButtonTypes.includes(item.type)) &&
                    <div className="card" key={item.type}>
                        <ul>
                            <li className="title">{item.ttl[language]}</li>
                            <li className="detail">{item.detail[language]}</li>
                        </ul>
                        <ul className="blow">
                            <li className="video">
                                <iframe
                                    src={item.video[language]}
                                    frameBorder="0"
                                    // width="700"
                                    height="220"
                                    allow="fullscreen"
                                    allowFullScreen></iframe>
                            </li>
                            <ul className="button">
                                {item.type === 'book' &&
                                    <li className=""
                                        onClick={
                                            !isAuthenticated() ?
                                                () => { signup() } :
                                                () => {
                                                    setIsBookOrdering(true)
                                                    setProductType(1)
                                                }}
                                    >
                                        {item.loginButton[language]}</li>}
                                {item.type === 'book' &&
                                    <li className=""
                                        onClick={() => {
                                            setIsBookOrdering(true)
                                            setProductType(2)
                                        }}
                                    >
                                        {item.button[language]}</li>}
                                {item.type === 'archive' &&
                                    <li className=""
                                        onClick={
                                            !isAuthenticated() ?
                                                () => { signup() } :
                                                () => {
                                                    setIsArchiveOrdering(true)
                                                    setProductType(3)
                                                }}
                                    >
                                        {item.loginButton[language]}</li>}
                            </ul>
                            {!isAuthenticated() && <li className="annotation">{item.annotation[language]}</li>}
                            <li className="price">
                                {item.type === 'book' && language === 'ja' &&
                                    <ul>
                                        <li><span className="tax">本とアーカイブの場合　</span>3600<span className="unit">{item.unit[language]}</span>{' '}
                                            <span className="tax">{item.tax[language]}</span></li>
                                        <li><span className="tax">本のみの場合　</span>3000<span className="unit">{item.unit[language]}</span>{' '}
                                            <span className="tax">{item.tax[language]}</span></li>
                                    </ul>
                                }
                                {item.type === 'archive' && language === 'ja' &&
                                    <ul>
                                        {/* <li>{'　'}</li> */}
                                        <li>{item.price[language]}<span className="unit">{item.unit[language]}</span>{' '}
                                            <span className="tax">{item.tax[language]}</span></li>
                                    </ul>
                                }
                                {item.type === 'archive' && language === 'en' &&
                                    <ul>
                                        <li><span className="unit">{item.unit[language]}</span>{item.price[language]}{' '}
                                            <span className="tax">{item.tax[language]}</span></li>
                                    </ul>
                                }


                            </li>
                            {item.type === 'book' && language === 'ja' && <li className="feature">
                                <h3>本の特典 : </h3>
                                {features['book']['ja'].map(feature => (
                                    <div key={feature}>
                                        <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-check" fill="#fed32b" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                                        </svg>
                                        {feature}</div>
                                ))}
                            </li>}
                            {item.type === 'archive' && language === 'ja' && <li className="feature">
                                <h3>アーカイブサイトの特典 : </h3>
                                {features['archive']['ja'].map(feature => (
                                    <div key={feature}>
                                        <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-check" fill="#fed32b" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                                        </svg>
                                        {feature}</div>
                                ))}
                                <div className="annotation">{features['annotation']['ja']}</div>
                            </li>}
                        </ul>
                    </div>
                ))}
            </section>}
            {language === 'en' && <section className='cv_top'>
                <div className="card" >
                    <ul className="en-grid">
                        <li>
                            <ul>
                                <li className="title">{items[1].ttl[language]}</li>
                                <li className="detail">{items[1].detail[language]}</li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li className="video">
                                    <iframe
                                        src={items[1].video[language]}
                                        frameBorder="0"
                                        // width="700"
                                        height="220"
                                        allow="fullscreen"
                                        allowFullScreen></iframe>
                                </li>
                                <li></li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="featureEnWrap">
                        <li className="feature">
                            {features['archive']['en'].map(feature => (
                                <div key={feature}>
                                    <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-check" fill="#fed32b" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                                    </svg>
                                    {feature}</div>
                            ))}
                            <div className="annotation">{features['annotation']['en']}</div>
                        </li>
                    </ul>
                    <ul className="button_en">
                        <li className="button"
                            onClick={
                                !isAuthenticated() ?
                                    () => { signup() } :
                                    () => {
                                        setIsArchiveOrdering(true)
                                        setProductType(3)
                                    }}
                        >
                            {items[1].loginButton[language]}</li>
                        <li className="price">
                            <ul>
                                {!isAuthenticated() && <li className="annotation">{items[1].annotation[language]}</li>}
                                <li><span className="unit">{items[1].unit[language]}</span>{items[1].price[language]}{' '}
                                    <span className="tax">{items[1].tax[language]}</span></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </section>}
        </>
    );
};

export default CvSection;
import React from 'react'
import { useIntl, Link } from "gatsby-plugin-intl"
import './css/askDefaulLang.styl'
// Framer motion and Variants
import { motion } from "framer-motion"
import { fadeInVariants } from '../../asset/variants-framer-motion/variants';

function AskDefaultLang({ handleDefaultLang, setIsAskingDefaultLang }) {
    const intl = useIntl()
    const language = intl.locale
    return (
        <div className="askDefaulLangModal">
            <motion.div
                className="contentArea"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible">
                <p className="sentence">
                    {language === 'ja' ?
                        'デフォルト言語を設定しますか？\nWhich language do you like as default?' :
                        'Which language do you like as default?\nデフォルト言語を設定しますか？'}
                </p>
                <ul className='button'>
                    <li onClick={() => {
                        handleDefaultLang('en')
                        setIsAskingDefaultLang(false)
                    }}>English</li>
                    <li onClick={() => {
                        handleDefaultLang('ja')
                        setIsAskingDefaultLang(false)
                    }}>日本語</li>
                    <li onClick={() => {
                        setIsAskingDefaultLang(false)
                    }}>{language === 'ja' ? '今は設定しない' : 'Select it later'}</li>

                </ul>
            </motion.div>
        </div>
    )
}

export default AskDefaultLang
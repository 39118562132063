import React from 'react'
import { useIntl, Link } from "gatsby-plugin-intl"
import './css/askBookPageSave.styl'
// Framer motion and Variants
import { motion } from "framer-motion"
import { fadeInVariants } from '../../asset/variants-framer-motion/variants';

function AskBookPageSave({
    initialPage,
    setInitialPage,
    toggler,
    setToggler,
    setIsAskingBookPageSave,
    totalPageNumber
}) {
    const intl = useIntl()
    const language = intl.locale
    return (
        <div className="askBookPageSaveModal">
            <motion.div
                className="contentArea"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible">
                <p className="sentence">{language === 'ja' ? 'どこからご覧になりますか？' : 'Where do you want to read from?'}</p>
                {/* <p className="sentence">次回ログインの際は、<br className="sp" />今回の続きからご覧になりますか？</p> */}
                <ul className='button'>
                    {!!initialPage && <li onClick={() => {
                        setIsAskingBookPageSave(false)
                        setToggler(!toggler)
                    }}>{language === 'ja' ? '続きから読む' : 'Page I read last time'}</li>}
                    <li onClick={() => {
                        setInitialPage(0)
                        setIsAskingBookPageSave(false)
                        setToggler(!toggler)
                    }}>{language === 'ja' ? '最初から読む' : 'First page'}</li>
                    <li onClick={() => {
                        setInitialPage(totalPageNumber)
                        setIsAskingBookPageSave(false)
                        setToggler(!toggler)
                    }}>{language === 'ja' ? '最後へ飛ぶ' : 'Last page'}</li>
                    <li onClick={() => {
                        setIsAskingBookPageSave(false)
                    }}>{language === 'ja' ? '戻る' : 'Cancel'}</li>
                </ul>
            </motion.div>
        </div>
    )
}

export default AskBookPageSave
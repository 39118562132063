export const cvText = [{
  type: "book",
  ttl: { en: "", ja: "本" },
  detail: { en: "", ja: "森と加賀谷が2011年より撮像してきた中から、厳選した51点の放射線像を集めた写真集になります。\n本とアーカイブサイトを同時購入すると、別々に購入した場合よりも1400円お得です。" },
  video: { en: "https://player.vimeo.com/video/461975291", ja: "https://player.vimeo.com/video/461975291" },
  button: { en: "", ja: "本のみ購入" },
  loginButton: { en: "", ja: "本とアーカイブを購入" },
  annotation: { en: 'If you haven\'t been logged in, a Sign up dialogue will appear. Please click "" again after logged in.', ja: '※アーカイブも同時購入する場合、サインアップ画面が開きます。サインアップの後、もう一度「本とアーカイブを購入」ボタンを押してください。' },
  price: { en: "", ja: "3600" },
  unit: { en: "$", ja: "円" },
  tax: { en: "", ja: "（送料・税込）" },
}, {
  type: "archive",
  ttl: { en: "Archive app", ja: "アーカイブサイト" },
  detail: { en: "This app includes all of archives (more than 170 works) and can display them both on desktop and mobile. You can manipulate 3D Autoradiographs, enlarge and compare with original samples' color photos and also check where it was sampled on Google map. Please click and take a look at some examples below.", ja: "170を超えるすべての放射線像をPC･モバイルどちらからでもご覧いただくことができます。カラー写真との比較、放射線像の拡大縮小、3次元放射線像の操作が可能です。下のサンプルをお試しください。" },
  video: { en: "https://player.vimeo.com/video/489729351", ja: "https://player.vimeo.com/video/448800165" },
  button: { en: "Purchase Archive", ja: "アーカイブアプリを開く" },
  loginButton: { en: "Purchase Archive", ja: "アーカイブを購入" },
  annotation: { en: 'If you haven\'t been logged in, a Sign up dialogue will appear.\nPlease click "Purchase Archive" again after logged in.', ja: '※サインアップ画面が開きます。サインアップの後、もう一度「アーカイブを購入」ボタンを押してください。' },
  price: { en: "19.00", ja: "2000" },
  unit: { en: "$", ja: "円" },
  tax: { en: "(with tax)", ja: "（税込）" },
}
]

export const cvSingleButtonText = {
  button: { en: "Open Archive", ja: "アーカイブを開く" },
  loginAndArchive: { en: "Login & Open Archive", ja: "ログイン／アーカイブを開く" },
}

// ※アーカイブも同時購入する場合、サインアップ画面が開きます。サインアップの後、もう一度「本とアーカイブを購入」ボタンを押してください。
// button: { en: "", ja: "すぐに本を購入" },
// loginButton: { en: "", ja: "サインアップして本を購入" },
// button: { en: "Open Archive", ja: "アーカイブアプリを開く" },
// loginButton: { en: "Login & Open Archive", ja: "サインアップ／アーカイブアプリを開く" },